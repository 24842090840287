import {
  AfterViewInit,
  Component,
  Inject,
  NgZone,
  OnInit,
  SecurityContext,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  ECCustomDateAdapter,
  ECCustomDateParserFormatter
} from '@core/datepicker/EDCustomDatepickerAdapter.service';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';

import { StateMessageService } from '@core/state-message/state-message-service';
import { OnDestroy } from '@angular/core';
import { LoadingModalService } from '../../loading-modal/loading-modal.service';
import { StartOption } from '../../application.api';
import moment from 'moment';
import {
  GettingStartedBaseComponent,
  GettingStartedPageBase
} from '@application/getting-started/getting-started-base.component';
import { ResumeModalComponent } from '@application/getting-started/resume-modal/resume-modal.component';
import { GettingStartedFormGroup } from '@application/getting-started/getting-started.form';
import { GettingStartedValidator } from '@application/getting-started/getting-started.validator';
import { GoToSigninModalComponent } from '@application/getting-started/goto-signin-modal/goto-signin-modal.component';
import { NoOptionModalComponent } from '@application/getting-started/no-option-modal/no-option-modal.component';
import { PartnerRegisterActionsService } from '@core/partner-register-actions/partner-register-actions.service';
import {
  IPartnerVerificationService,
  PARTNER_VERIFICATION_SERVICE
} from '@core/partner/partner-verification.interface';
import { PartnerVerificationModalComponent } from '../partner-verification-modal/partner-verification-modal.component';
import {
  PartnerApplicantModel,
  PartnerLeadIdModel,
  VerifiedResponseModel
} from '@core/partner/partner-verification.models';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TextInputComponent } from '@elevate/ui-components';
import { Environment } from '@environment/environment';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import { UserInactivityStatusService } from '@core/inactivity-module/user-inactivity.service';
import { StateEligibilityService } from '@application/getting-started/state-eligibility/state-eligibility.service';
import { AppInsightsService } from '@core/app-insights/app-insights.service';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { LegacyCmsService } from '@core/legacy-cms/legacy-cms.service';
import { CmsGettingStarted } from '@application/getting-started/getting-started.content';
import { TodayCardHandleService } from '@core/brand-handle-services/today-card-handle.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { ConfigurationService } from '@core/configuration/configuration.service';
import { ApplicationFlow } from '@application/application';
import {ApplicationFlowService} from '@core/application-flow/application-flow.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  templateUrl: '../../getting-started/getting-started.component.html',
  styleUrls: ['../../getting-started/getting-started.component.scss'],
  providers: [
    GettingStartedFormGroup,
    GettingStartedValidator,
    {
      provide: NgbDateAdapter,
      useClass: ECCustomDateAdapter
    },
    {
      provide: NgbDateParserFormatter,
      useClass: ECCustomDateParserFormatter
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class PartnerLandingComponent extends GettingStartedBaseComponent
  implements OnInit, OnDestroy, AfterViewInit, GettingStartedPageBase {
  @ViewChild('ssn') private elementRef: TextInputComponent;
  @ViewChild('email') private emailInput: TextInputComponent;
  public pageSubTitle: string;
  public pageContent: SafeHtml;
  public showDebugHud = '';
  public recaptchaIsHidden = true;
  public isPartnerPrePop = true;
  public ssnHasBeenDeleted = false;
  public leadId: string;
  public gcid: string;
  public audId: string;
  public offerId: string;
  public partnerIds: PartnerLeadIdModel;
  public disclosurePageContent: SafeHtml;
  public applicantInfo: VerifiedResponseModel;
  private verificationResponse;
  private getLeadIdResponse;
  private phoneNumberLast4Response;
  private nextPage: string;
  public formChangesSubscription: Subscription;

  constructor(
    public form: GettingStartedFormGroup,
    public route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public stateMessageService: StateMessageService,
    public modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public ngZone: NgZone,
    @Inject(PARTNER_VERIFICATION_SERVICE)
    private partnerService: IPartnerVerificationService,
    private partnerRegisterActionsService: PartnerRegisterActionsService,
    private loadingService: LoadingModalService,
    private cmsPageContentService: CmsPageContentService,
    private legacyCmsService: LegacyCmsService,
    public environment: Environment,
    public googleanalytics: GoogleAnalytics,
    private userAppStarted: UserInactivityStatusService,
    public validators: GettingStartedValidator,
    public stateEligibilityService: StateEligibilityService,
    public appInsightsService: AppInsightsService,
    public todayCardHandleService: TodayCardHandleService,
    public configurationService: ConfigurationService,
    public applicationFlowService: ApplicationFlowService,
    public currencyPipe: CurrencyPipe
  ) {
    super(
      form,
      modalService,
      stateMessageService,
      validators,
      stateEligibilityService,
      googleanalytics,
      appInsightsService,
      environment,
      route,
      todayCardHandleService,
      configurationService,
      currencyPipe
    );

    this.initiateCmsPageContent();

    this.activatedRoute.queryParams.subscribe(async (params: Params) => {
      this.leadId = params['lid'];
      this.gcid = params['gcid'];
      this.audId = params['aud_id'];
      this.offerId = params['oid'];
      if(!this.offerId){
        this.nextPage = this.applicationFlowService.getContinuePath(null, ApplicationFlow.PartnerPrepop);
      }else{
        this.nextPage = this.applicationFlowService.getContinuePath(null, ApplicationFlow.PartnerPrequal);
      }
      var gtagdta: any = {
        lead_id: this.leadId,
        aud_id: this.audId,
        gcid: this.gcid,
        offer_id: this.offerId,
        full_referral_url: document.referrer,
        userType: ApplicationFlow.PartnerPrepop
      };
      this.googleanalytics.setGoogleTagManagerVariables(gtagdta);
      
      if (this.environment.developerMode) {
        this.cmsPageContent = this.route.snapshot.data.cmsContent.prequalificationGettingStarted.gettingStartedCommon;
      }

      if (this.audId) {
        try {
          this.getLeadIdResponse = await lastValueFrom(
            this.partnerService.getLeadIdLendingTree(this.gcid, this.audId)
          );
          this.leadId = this.getLeadIdResponse.body.leadId;
        } catch {
          this.ngZone.run(() => this.router.navigate(['/getting-started']));
          return;
        }
      }
      this.partnerIds = { leadId: this.leadId };
      this.partnerRegisterActionsService.registerPartnerAction(
        this.partnerIds,
        'click'
      );
      this.loadingService.open();
      try {
        this.verificationResponse = await lastValueFrom(
          this.partnerService.getVerificationOptions(this.leadId)
        );
        this.phoneNumberLast4Response = await lastValueFrom(
          this.partnerService.getLast4PhoneNoPrepop(this.leadId)
        );
        this.openVerificationModal();
      } catch {
        this.ngZone.run(() => this.router.navigate(['/getting-started']));
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.form.appendDisclosures();
  }

  public ngAfterViewInit(): void {
    this.legacyCmsService.replaceTag('a.privacyPolicyLink', {
      attribute: {
        id: 'privacyPolicyLink',
        href: `${this.environment.bau.url}/privacy-policy`,
        target: '_blank'
      }
    });
    this.formConfig.ssnConfig.hidden = 'always';
  }
  public async onFocusOut(event: any): Promise<void> {
    this.googleAnalytics.formInteractionEvent({
      step_name: event.target.id
    });
  }

  public onAddressSuggestionError(event: any) {
    this.appInsightsService.trackException(event);
  } 

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.formChangesSubscription != null) {
      this.formChangesSubscription.unsubscribe();
    }
  }

  public fillPartnerForm(partnerInfo: PartnerApplicantModel): void {
    this.form.patchValue({
      firstname: partnerInfo.firstName,
      lastname: partnerInfo.lastName,
      suffix: partnerInfo.suffix,
      dob: moment(partnerInfo.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY'),
      ssn: `55555${partnerInfo.last4ssn}`,
      addressLine1: partnerInfo.address.line1,
      addressLine2: partnerInfo.address.line2,
      city: partnerInfo.address.city,
      state: partnerInfo.address.stateCode,
      postalCode: partnerInfo.address.zipCode,
      mobile: partnerInfo.mobileNumber.replace(/\D/g, ''),
      email: partnerInfo.email
    });
    this.onStateUpdate(partnerInfo.address.stateCode);
    this.form.get('dob').markAsTouched({ onlySelf: true });
    this.userAppStarted.setUserApplicationStatus(true);
    this.elementRef.setDisabledState(true);
  }

  public async submit(): Promise<void> {
    this.form.showValidationErrors();

    if (super.isStateNotEligible()) {
      return;
    }

    if (!this.form.valid) {
      return;
    }
  }

  public openNoOptionsModal(): void {
    const noOptionModalRef = this.modalService.open(NoOptionModalComponent);
    noOptionModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.noOptionModal;
    noOptionModalRef.result.then(() => {
      this.form.controls.email.markAsTouched();
      this.form.controls.email.markAsDirty();
      this.emailInput.input.nativeElement.focus();
      this.form.controls.email.updateValueAndValidity();
    });
  }

  private openVerificationModal(): void {
    this.loadingService.close();
    const partnerModalRef = this.modalService.open(
      PartnerVerificationModalComponent,
      {
        backdrop: 'static',
        windowClass: 'verification-modal'
      }
    );
    partnerModalRef.componentInstance.data = {
      ...this.partnerIds,
      phoneNumberLast4: this.phoneNumberLast4Response.body.phoneNumberLast4,
      isSsnVerification: this.verificationResponse.body.ssnVerification,
      isOtpVerification: this.verificationResponse.body.otpVerification
    };
    partnerModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.partnerVerificationModal;
    partnerModalRef.result.then(data =>
      this.handalVerificationModalResponse(data)
    );
  }

  private initiateCmsPageContent(): void {
    this.route.data.pipe(takeUntil(this.onDestroyed)).subscribe((data: any) => {
      this.cmsPageContent = data.cmsContent.prequalificationPartner
        .gettingStartedCommon as CmsGettingStarted;
      this.form.build(this.cmsPageContent.validationMessages);
      const dateRangeMessage = this.cmsPageContent.validationMessages
        .dateOfBirth.otherErrorMessage;
      this.formConfig.dobConfig.errorDateRange = dateRangeMessage
        ? dateRangeMessage
        : this.formConfig.dobConfig.errorDateRange;
      this.pageSubTitle = this.cmsPageContent.subHeader;
      this.cmsPageContentService.updatePageTitle(
        this.cmsPageContent.welcomeTitle
      );

      this.disclosurePageContent = this.cmsPageContent.disclosuresInfo;
      this.pageContent = this.sanitizer.sanitize(
        SecurityContext.HTML,
        this.cmsPageContent.content
      );
    });
  }

  private handalVerificationModalResponse(
    response: VerifiedResponseModel
  ): void {
    if (response) {
      this.applicantInfo = response;
      const title = this.cmsPageContent.welcomeNameTitle.replace(
        '{name}',
        this.applicantInfo.partnerLead.partnerName
      );
      this.cmsPageContentService.updatePageTitle(title);

      switch (this.applicantInfo.startOptions) {
        case StartOption.NoOptions:
          this.loadingService.close();
          this.fillPartnerForm(this.applicantInfo.partnerLead.applicant);
          this.form.duplicateEmail = this.applicantInfo.partnerLead.applicant.email;
          this.openNoOptionsModal();
          return;
        case StartOption.RedirectToBau:
          this.loadingService.close();
          const goToModalRef = this.modalService.open(
            GoToSigninModalComponent,
            {
              backdrop: 'static',
              windowClass: 'goto-signin-modal'
            }
          );
          goToModalRef.componentInstance.userEmail = this.applicantInfo.partnerLead.applicant.email;
          goToModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.goToSigninModal;
          return;
        case StartOption.StartNewOnly:
          this.fillPartnerForm(this.applicantInfo.partnerLead.applicant);
          return;
        case StartOption.ResumeOrStartNew:
        case StartOption.ResumeOnly: {
          this.loadingService.close();
          const resumeModalRef = this.modalService.open(ResumeModalComponent, {
            backdrop: 'static',
            windowClass: 'resume-modal'
          });
          resumeModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.resumeModal;
          resumeModalRef.componentInstance.data = {
            phoneNumberLast4: this.phoneNumberLast4Response.body
              .phoneNumberLast4,
            applicationId: this.applicantInfo.applicationId,
            startOptions: this.applicantInfo.startOptions,
            gcid: this.gcid,
            showCloseBtn:
              this.applicantInfo.startOptions === StartOption.ResumeOnly
                ? false
                : true,
            firstName: this.applicantInfo.partnerLead.applicant.firstName
          };
          resumeModalRef.result.then(resumeData => {
            if (resumeData && !resumeData.resumeApplication) {
              this.fillPartnerForm(this.applicantInfo.partnerLead.applicant);
            }
          });
          return;
        }
      }
    }
  }
}
