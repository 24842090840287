import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationResolver } from '@application/application.resolver';
import { BankInformationComponent } from '@application/bank-information/bank-information.component';
import { CheckingOptionsComponent } from '@application/checking-options/checking-options.component';
import { ConnectBankMoreInformationComponent } from '@application/connect-bank-more-information/connect-bank-more-information.component';
import { ConnectBankComponent } from '@application/connect-bank/connect-bank.component';
import { ApplicationContinuePathGuard } from '@application/continue-path/continue-path.guard';
import { DeclineComponent } from '@application/decline-page/decline.component';
import { FundingPaymentSelectionComponent } from '@application/funding-payment-selection/funding-payment-selection.component';
import { GettingStartedComponent } from '@application/getting-started/getting-started.component';
import { InReviewComponent } from '@application/underwriting-decisions/in-review/in-review.component';
import { IncomeVerificationComponent } from '@application/income-verification/income-verification.component';
import { IncomeComponent } from '@application/income/income.component';
import { PrequalApprovalComponent } from '@application/prequalification-decisions/prequal-approval/prequal-approval.component';
import { RestrictNavigationGuard } from '@application/restrict-navigation/restrict-navigation-guard';
import { UnderwritingApprovalComponent } from '@application/underwriting-decisions/underwritng-approval/underwriting-approval.component';
import { VerifyInfoComponent } from '@application/verify-info/verify-info.component';
import { LayoutComponent } from '@layout/layout.component';
import { ErrorComponent } from './application/error/error.component';
import { SignAndSubmitComponent } from './application/sign-and-submit/sign-and-submit.component';
import { AccountSetupComponent } from '@application/account-setup/account-setup.component';
import { FinalPageComponent } from '@application/final-page/final-page.component';
import { BookingComponent } from './application/booking/booking.component';
import { IdentityGuardService } from '@core/identity-service/identity-guard.service';
import { ReapplyComponent } from './application/getting-started/reapply/reapply.component';
import { PartnerLandingComponent } from '@application/partner/partner-landing-page/partner-landing.component';
import { PendingComponent } from '@application/pending/pending.component';
import { CmsPageContentResolver } from '@core/cms/resolvers/cms-page-content.resolver';
import { ProcessingDocsComponent } from '@application/processing-docs/processing-docs.component';
import { DocumentUploadPageComponent } from '@application/document-upload-page/document-upload-page.component';
import { AdditionalInfoBankComponent } from '@application/underwriting-decisions/additional-info-bank/additional-info-bank.component';
import { PaymentMethodSelectionComponent } from '@application/payment-method-selection/payment-method-selection.component';
import { PaymentGuardService } from '@core/application-flow/payment-guard.service';
import { NoiaComponent } from '@application/underwriting-decisions/notice-of-incomplete-action/notice-of-incomplete-action.component';
import { ChooseYourTermsComponent } from '@application/underwriting-decisions/choose-your-terms/choose-your-terms.component';

const bankIncomeIncludeRefs = [
  'connect_bank.disclosures',
  'connect_bank.account_type_modal',
  'connect_bank.consents_section.consent',
  'connect_bank.dynamic_disclosures',
  'bank_income_connect_bank_new_design',
  'new_connect_bank',
  'connect_bank.dynamic_disclosures.bank_data_provider.finicity',
  'connect_bank.dynamic_disclosures.bank_data_provider.plaid',
  'connect_bank.checking_account_modal',
  'connect_bank.consents_section.consent.default_consent',
  'connect_bank.consents_section.consent.states_consents.consent',
  'funding_payment_selection.consents_section.consent',
  'funding_payment_selection.consents_section.consent.default_consent',
  'funding_payment_selection.consents_section.consent.states_consents.consent',
  'bank_information.consents_section.consent',
  'bank_information.consents_section.consent.default_consent',
  'bank_information.consents_section.consent.states_consents.consent',
  'bank_information.giact_codes',
  'd_income',
  'consent_modals',
  'income_section.consents_section.consent',
  'income_section.consents_section.consent.default_consent',
  'income_section.consents_section.consent.states_consents.consent',
  'verify_income.consents_section.consent',
  'verify_income.consents_section.consent.default_consent',
  'verify_income.consents_section.consent.states_consents.consent',
  'sign_and_submit',
  'sign_and_submit.consents_section.consent.default_consent',
  'sign_and_submit.consents_section.consent.states_consents.consent'
];

const prequalificationIncludeRefs = [
  'checking_options',
  'decline',
  'offer_details',
  'state_messages',
  'prequalification_approval',
  'prequalification_approval.consents_section.consent',
  'prequalification_approval.consents_section.consent.default_consent',
  'prequalification_approval.consents_section.consent.states_consents.consent',
  'requested_amount',

  'prequalification_connect_bank_more_info.disclosures',
  'prequalification_connect_bank_more_info.account_type_modal',
  'prequalification_connect_bank_more_info.dynamic_disclosures',
  'prequalification_connect_bank_more_info.dynamic_disclosures.bank_data_provider.finicity',
  'prequalification_connect_bank_more_info.dynamic_disclosures.bank_data_provider.plaid',
  'prequalification_connect_bank_more_info.checking_account_modal',
  'consent_modals',

  'verify_info.consents_section.consent',
  'verify_info.consents_section.consent.default_consent',
  'verify_info.consents_section.consent.states_consents.consent',
  'prequalification_mailing_address',
  'prequalification_getting_started.getting_started_common.address_labels.address_component',
  'prequalification_getting_started.getting_started_common.modals.resume_modal',
  'prequalification_getting_started.getting_started_common.modals.partner_verification_modal',
  'prequalification_getting_started.getting_started_common.disclosures.consent_to_communication',
  'prequalification_getting_started.getting_started_common.disclosures.consent_to_communication.default_consent',
  'prequalification_getting_started.getting_started_common.disclosures.consent_to_communication.states_consents.consent',
  'prequalification_getting_started.consents_section.consent',
  'prequalification_getting_started.consents_section.consent.default_consent',
  'prequalification_getting_started.consents_section.consent.states_consents.consent',
  'prequalification_getting_started.redirect_to_legacy',

  'prequalification_reapply.getting_started_common.modals.resume_modal',
  'prequalification_reapply.getting_started_common.modals.partner_verification_modal',
  'prequalification_reapply.getting_started_common.disclosures.consent_to_communication',
  'prequalification_reapply.getting_started_common.disclosures.consent_to_communication.default_consent',
  'prequalification_reapply.getting_started_common.disclosures.consent_to_communication.states_consents.consent',
  'prequalification_reapply.consents_section.consent',
  'prequalification_reapply.consents_section.consent.default_consent',
  'prequalification_reapply.consents_section.consent.states_consents.consent',

  'prequalification_partner.getting_started_common.modals.resume_modal',
  'prequalification_partner.getting_started_common.modals.partner_verification_modal',
  'prequalification_partner.getting_started_common.disclosures.consent_to_communication',
  'prequalification_partner.getting_started_common.disclosures.consent_to_communication.default_consent',
  'prequalification_partner.getting_started_common.disclosures.consent_to_communication.states_consents.consent',
  'prequalification_partner.consents_section.consent',
  'prequalification_partner.consents_section.consent.default_consent',
  'prequalification_partner.consents_section.consent.states_consents.consent'
];

const bookingIncludeRefs = [
  'account_setup.password',
  'booking_waiting',
  'consent_modals',
  'successful_setup.post_booking_loan_details',
  'payment_method_selection.consents_section.consent.default_consent',
  'payment_method_selection.consents_section.consent.states_consents.consent'
];

const underwritingIncludeRefs = [
  'additional_info',
  'additional_info_bank',
  'approval',
  'in_review',
  'pending',
  'choose_your_terms',
  'document_upload',
  'document_upload.document_upload',
  'processing_docs',
  'processing_docs.document_upload',
  'decline',
  'consent_modals',
  'additional_info_bank.disclosures',
  'additional_info_bank.dynamic_disclosures',
  'additional_info_bank.dynamic_disclosures.bank_data_provider.finicity',
  'additional_info_bank.dynamic_disclosures.bank_data_provider.plaid',
  'additional_info_bank.checking_account_modal',
  'additional_info_bank.account_type_modal',
  'freeze'
];

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    resolve: {
      sharedCmsContent: CmsPageContentResolver
    },
    data: {
      cmsContentTypeUid: 'shared',
      flow: 'application'
    },
    children: [
      { path: '', redirectTo: 'getting-started', pathMatch: 'full' },
      {
        path: 'getting-started',
        component: GettingStartedComponent,
        canActivate: [ApplicationContinuePathGuard],
        resolve: {
          cmsContent: CmsPageContentResolver
        },
        data: {
          title: 'Getting Started',
          cmsContentTypeUid: 'prequalification',
          cmsContentReferences: prequalificationIncludeRefs
        }
      },
      {
        path: 'reapply',
        component: ReapplyComponent,
        resolve: {
          cmsContent: CmsPageContentResolver
        },
        data: {
          title: 'Reapply',
          cmsContentTypeUid: 'prequalification',
          cmsContentReferences: prequalificationIncludeRefs
        }
      },
      {
        path: 'partner',
        component: PartnerLandingComponent,
        resolve: {
          cmsContent: CmsPageContentResolver
        },
        data: {
          title: 'Partner',
          cmsContentTypeUid: 'prequalification',
          cmsContentReferences: prequalificationIncludeRefs
        }
      },
      {
        path: '',
        canActivate: [ApplicationContinuePathGuard],
        resolve: {
          applicationData: ApplicationResolver
        },
        children: [
          {
            path: '',
            resolve: {
              cmsContent: CmsPageContentResolver
            },
            data: {
              cmsContentTypeUid: 'prequalification',
              cmsContentReferences: prequalificationIncludeRefs
            },
            children: [
              {
                path: 'verify-info',
                component: VerifyInfoComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Verify Information'
                }
              },
              {
                path: 'checking-options',
                component: CheckingOptionsComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Checking Options'
                }
              },
              {
                path: 'pre-qualification-approval',
                component: PrequalApprovalComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Prequal Approval'
                }
              },
              {
                path: 'pre-qualification-decline',
                component: DeclineComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Prequal Decline'
                }
              },
              {
                path: 'connect-bank-more-info',
                component: ConnectBankMoreInformationComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Need More Information'
                }
              }
            ]
          },
          {
            path: '',
            resolve: {
              cmsContent: CmsPageContentResolver
            },
            data: {
              cmsContentTypeUid: 'bank_income',
              cmsContentReferences: bankIncomeIncludeRefs
            },
            children: [
              {
                path: 'connect-bank',
                component: ConnectBankComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Connect Bank'
                }
              },
              {
                path: 'bank-information',
                component: BankInformationComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Bank Information'
                }
              },
              {
                path: 'funding-payment-selection',
                component: FundingPaymentSelectionComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Funding Payment Selection'
                }
              },
              {
                path: 'income',
                component: IncomeComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Income'
                }
              },
              {
                path: 'verify-income',
                component: IncomeVerificationComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Verify Income'
                }
              },
              {
                path: 'sign-and-submit',
                component: SignAndSubmitComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Sign and Submit'
                }
              }
            ]
          },
          {
            path: '',
            resolve: {
              cmsContent: CmsPageContentResolver
            },
            data: {
              cmsContentTypeUid: 'underwriting',
              cmsContentReferences: underwritingIncludeRefs
            },
            children: [
              {
                path: 'in-review',
                component: InReviewComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'In Review'
                }
              },
              {
                path: 'additional-info',
                component: DocumentUploadPageComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Request for Additional Information'
                }
              },
              {
                path: 'processing-docs',
                component: ProcessingDocsComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Processing docs'
                }
              },
              {
                path: 'additional-info-bank',
                component: AdditionalInfoBankComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Additional Info Bank'
                }
              },
              {
                path: 'approval',
                component: UnderwritingApprovalComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Underwriting Approval'
                }
              },
              {
                path: 'pending',
                component: PendingComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Pending'
                }
              },
              {
                path: 'decline',
                component: DeclineComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Underwriting Decline'
                }
              },
              {
                path: 'notice-of-incomplete-action',
                component: NoiaComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Notice of Incomplete Action'
                }
              },
              {
                path: 'cyt-installment',
                component: ChooseYourTermsComponent,
                data: {
                  title: 'Choose-Your-Terms'
                }
              }
            ]
          },
          {
            path: '',
            resolve: {
              cmsContent: CmsPageContentResolver
            },
            data: {
              cmsContentTypeUid: 'booking',
              cmsContentReferences: bookingIncludeRefs
            },
            children: [
              {
                path: 'payment-method-selection',
                component: PaymentMethodSelectionComponent,
                canDeactivate: [RestrictNavigationGuard],
                canActivate: [PaymentGuardService],
                data: {
                  title: 'Payment Method Selection'
                }
              },
              {
                path: 'account-setup',
                component: AccountSetupComponent,
                canDeactivate: [RestrictNavigationGuard],
                canActivate: [IdentityGuardService],
                data: {
                  title: 'Account Setup'
                }
              },
              {
                path: 'booking',
                component: BookingComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Booking-your-loan'
                }
              },
              {
                path: 'successful-setup',
                component: FinalPageComponent,
                canDeactivate: [RestrictNavigationGuard],
                data: {
                  title: 'Successful Setup'
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: LayoutComponent,
    resolve: {
      sharedCmsContent: CmsPageContentResolver
    },
    data: {
      cmsContentTypeUid: 'shared',
      flow: 'application'
    },
    children: [
      { path: '', redirectTo: 'getting-started', pathMatch: 'full' },
      {
        path: 'error',
        component: ErrorComponent,
        resolve: {
          sharedCmsContent: CmsPageContentResolver
        },
        data: {
          title: 'Error',
          cmsContentTypeUid: 'shared',
          flow: 'application'
        }
      }
    ]
  },
  { path: '**', redirectTo: 'getting-started', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
