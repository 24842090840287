<div class="layout-container">
  <ecl-header
    *ngIf="rawHeaderCms$ | async"
    id="bauHeaderLink"
    [rawHeaderCms]="rawHeaderCms$ | async"
    [publicBaseUrl]="publicBaseUrl"
    [banners]="banners$ | async"
    (closeBanner)="closeBanner($event)"
    (redirectHome)="logoClicked($event)"
    (signIn)="signIn($event)"
    unreadMessages="0"
  ></ecl-header>

  <div class="main-page-container">
    <header class="row">
      <rise-five-day-bar
        class="d-none d-xl-block risk-free-msg"
        id="riseFiveDayRiskFreeMsg"
        *ngIf="fiveDayRiskFreeGuarantee$ | async"
        [cmsPageContent]="fiveDayRiskFreeGuarantee$ | async"
      ></rise-five-day-bar>
      <div class="offset-lg-1 col-lg-10">
        <app-page-header></app-page-header>
      </div>
    </header>

    <main class="row">
      <div class="offset-lg-1 col-lg-10 col-xl-6">
        <router-outlet></router-outlet>
      </div>

      <div class="offset-lg-1 d-none d-xl-block col-xl-3  sidebar-top-margin">
        <app-sidebar [isCobrowseEnabled]="isCobrowseEnabled"></app-sidebar>
      </div>
    </main>
  </div>
  <footer>
    <ecl-footer
      [footerContent]="footerContent$ | async"
      [publicBaseUrl]="publicBaseUrl"
      [accountBaseUrl]="accountBaseUrl"
      [cobrowse]="cobrowse"
      (click)="onNavClick($event)"
    ></ecl-footer>
  </footer>
  <div
    class="app-cookie-notification-container"
    role="alert"
    *ngIf="showCookieNotification"
  >
    <ecl-cookie-notification
      (isNotificationShowing)="getNotificationStatus($event)"
      [notification]="cookieNotificationContent$ | async"
    ></ecl-cookie-notification>
  </div>
  <div>
    <app-feedback></app-feedback>
  </div>
</div>
