import {
  Component,
  ViewEncapsulation,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewChecked,
  AfterViewInit
} from '@angular/core';
import { Accordion, AccordionGroup, RadioGroup } from '@elevate/ui-components';
import {
  ConnectBankForm,
  ConnectBankFormValidationMessages
} from './connect-bank.form';
import { ActivatedRoute, Router } from '@angular/router';
import { PlaidService } from '../../core/plaid/plaid.service';
import { HandleBankConnectionResponseService } from '../../core/connect-bank/handle-bank-connection-response.service';
import { Subscription } from 'rxjs';
import { ConnectBankDebugService } from './debug-hud/debug-hud.service';
import { AccountTypeModalCmsContents } from './account-type-modal/account-type-modal.component';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { ConsentSectionItem } from '@application/consents/consents.content';
import { ConsentHelper } from '@application/consents/consent.helper';
import { ConsentsComponent } from '@application/consents/consents.component';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import { ApplicationDataService } from '../application.service';
import { BankDataProviders } from '@application/application';
import { FinicityService } from '@core/finicity/finicity.service';
import { BankConnectSuccessObject } from '@core/connect-bank/bank-connect.types';
import { AccessibilityService } from '@core/accessibility/accessibility.service';
import { CheckingAccountModalCmsContents } from './checking-account-modal/checking-account-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DefaultModalComponent } from '@core/default-modal/default-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import { LocationProxy } from '@core/location-proxy/location-proxy.service';
import { ThirdPartyDisclaimerModalComponent } from '@layout/thirdparty-disclaimer-modal/thirdparty-disclaimer-modal.component';

export interface ConnectBankCmsContents {
  title: string;
  header: string;
  content: string;
  dynamicDisclosures: {
    title: string;
    bankDataProvider: {
      finicity: {
        title: string;
        disclosures: Accordion[];
      };
      plaid: {
        title: string;
        disclosures: Accordion[];
      };
    };
  };

  selectBoxName: string;
  selectBox: {
    optionLogAccount: string;
    optionManualEnterAccount: string;
  };
  validationMessages: ConnectBankFormValidationMessages;
  buttons: {
    continue: string;
  };
  accountTypeModal: AccountTypeModalCmsContents;
  checkingAccountModal: CheckingAccountModalCmsContents;
  errors: {
    bankConnectionErrorPlaid: string;
    bankConnectionErrorFinicity: string;
    accountNotPermittedError: string;
    bankConnectionAchErrorFinicity: string;
  };
  consentsSection: ConsentSectionItem[];
}
export interface NewConnectBankCmsContents {
  title: string;
  header: string;
  content: string;
  subtitle: string;
  dynamicDisclosures: {
    title: string;
    bankDataProvider: {
      finicity: {
        title: string;
        disclosures: Accordion[];
      };
      plaid: {
        title: string;
        disclosures: Accordion[];
      };
    };
  };
  connectBankOption: {
    label: string;
    detailText: string;
  };
  manualOption: {
    label: string;
    detailText: string;
  };
  findModal: {
    buttonText: string;
    content: string;
    header: string;
  };
  learnModal: {
    buttonText: string;
    content: string;
    header: string;
  };
  selectBoxName: string;
  selectBox: {
    optionLogAccount: string;
    optionManualEnterAccount: string;
  };
  validationMessages: ConnectBankFormValidationMessages;
  buttons: {
    continue: string;
  };
  accountTypeModal: AccountTypeModalCmsContents;
  checkingAccountModal: CheckingAccountModalCmsContents;
  errors: {
    bankConnectionErrorPlaid: string;
    bankConnectionErrorFinicity: string;
    accountNotPermittedError: string;
    bankConnectionAchErrorFinicity: string;
  };
  consentsSection: ConsentSectionItem[];
}
export interface ConnectBankAccordionCmsContent {
  accordionTitle: string;
  accordion: string;
}

export interface ConectBankCheckBoxesCmsContent {
  checkboxTitle: string;
  checkboxDescription: string;
}

export enum ConnectBankEnumOptions {
  Plaid = 'plaid',
  Manual = 'manual',
  Finicity = 'finicity'
}

@Component({
  selector: 'app-connect-bank',
  templateUrl: './connect-bank.component.html',
  styleUrls: ['./connect-bank.component.scss'],
  providers: [HandleBankConnectionResponseService, ConnectBankDebugService],
  encapsulation: ViewEncapsulation.None
})
export class ConnectBankComponent
  implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
  @ViewChild('consentsComponent') private consentsComponent: ConsentsComponent;
  private cmsData: ConnectBankCmsContents;
  public whyDoWeNeedThisInformationDisclosure: Accordion;
  public whatHappensWhenIConnectDisclosure: Accordion;
  public pageTextContent: string;
  public subSectionTitle: string;
  public bankProvider: BankDataProviders;
  public checkboxPlaidDescription: string;
  public checkboxManualDescription: string;
  public continueButton: string;
  public plaidSuccessSubscription: Subscription;
  public finicitySuccessSubscription: Subscription;
  public continueSubscription: Subscription;
  public groupAccordionConfig: AccordionGroup;
  public form: ConnectBankForm;
  public bankConnectionError: string;
  public achErrorFinicity: string;
  public accountNotPermittedError: string;
  public consentsSection: ConsentSectionItem[];
  public formChangesSubscription: Subscription;
  public isFinicity: boolean;
  public newDesign: NewConnectBankCmsContents;
  public executeRadioOptionOrderAbTest: boolean = false;
  public connectBankOptionConfig: RadioGroup = {
    direction: 'column',
    value: '',
    buttons: [
      {
        id: 'connectBankBankDataProviderRadioOption',
        name: 'connectBankOptionsRadioGroup',
        label: '',
        value: '',
        attributes: {
          'data-nid-target': 'connectOnlineRadioButton'
        }
      },
      {
        id: 'connectBankManualRadioOption',
        name: 'connectBankOptionsRadioGroup',
        label: '',
        value: ConnectBankEnumOptions.Manual,
        attributes: {
          'data-nid-target': 'enterManuallyRadioButton'
        }
      }
    ]
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private plaidService: PlaidService,
    public modalService: NgbModal,
    public handleBankConnectionResponseService: HandleBankConnectionResponseService,
    public connectBankDebugHudService: ConnectBankDebugService,
    private cmsPageContentService: CmsPageContentService,
    private applicationFlowService: ApplicationFlowService,
    private applicationDataService: ApplicationDataService,
    public finicityService: FinicityService,
    private adaAccessibility: AccessibilityService,
    public sanitizer: DomSanitizer,
    public googleAnalytics: GoogleAnalytics,
    private locationProxy: LocationProxy
  ) {
    this.newDesign = this.route.snapshot.data.cmsContent?.newConnectBank;
    this.cmsData = this.route.snapshot.data.cmsContent
      .connectBank as ConnectBankCmsContents;
    this.isFinicity =
      this.applicationDataService
        .getApplication()
        .product.bankDataProvider.toUpperCase() === BankDataProviders.Finicity
        ? true
        : false;
    this.connectBankOptionConfig.buttons[0].value = this.isFinicity
      ? ConnectBankEnumOptions.Finicity
      : ConnectBankEnumOptions.Plaid;

    const pageHeader = this.newDesign?.header || this.cmsData.header;
    const validationMsgs =
      this.newDesign?.validationMessages || this.cmsData.validationMessages;

    this.cmsPageContentService.updatePageTitle(pageHeader);
    this.form = new ConnectBankForm(validationMsgs);

    this.pageTextContent = this.newDesign?.content || this.cmsData.content;

    if (this.newDesign) {
      this.connectBankOptionConfig.direction = 'row';

      const abtestOrderOfBankingOptionsSwitched = applicationDataService
      .getApplication()
      .abTesting?.find(
        abtest => abtest.testName === 'ABTestingConnectBankOptions'
      );

    this.executeRadioOptionOrderAbTest =
      abtestOrderOfBankingOptionsSwitched?.segmentName ===
      'OrderOfBankingOptionsSwitched'
        ? true
        : false;
    }

    this.subSectionTitle =
      this.newDesign?.subtitle || this.cmsData.selectBoxName;
    this.bankProvider = this.applicationDataService.getApplication().product.bankDataProvider;

    this.connectBankOptionConfig.buttons[0].label =
      this.newDesign?.connectBankOption.label ||
      this.cmsData.selectBox.optionLogAccount;
    this.connectBankOptionConfig.buttons[1].label =
      this.newDesign?.manualOption.label ||
      this.cmsData.selectBox.optionManualEnterAccount;

    this.continueButton =
      this.newDesign?.buttons.continue || this.cmsData.buttons.continue;

    if (this.isFinicity) {
      this.whyDoWeNeedThisInformationDisclosure = this.cmsData.dynamicDisclosures.bankDataProvider.finicity.disclosures[0];
      this.whatHappensWhenIConnectDisclosure = this.cmsData.dynamicDisclosures.bankDataProvider.finicity.disclosures[1];
      this.bankConnectionError = this.cmsData.errors.bankConnectionErrorFinicity;
      this.achErrorFinicity = this.cmsData.errors.bankConnectionAchErrorFinicity;
    } else {
      this.whyDoWeNeedThisInformationDisclosure = this.cmsData.dynamicDisclosures.bankDataProvider.plaid.disclosures[0];
      this.whatHappensWhenIConnectDisclosure = this.cmsData.dynamicDisclosures.bankDataProvider.plaid.disclosures[1];
      this.bankConnectionError = this.cmsData.errors.bankConnectionErrorPlaid;
    }
    this.accountNotPermittedError = this.cmsData.errors.accountNotPermittedError;
    this.consentsSection = this.cmsData.consentsSection;
    this.consentsSection = !this.consentsSection
      ? []
      : !Array.isArray(this.consentsSection)
      ? [this.consentsSection]
      : this.consentsSection;

    this.groupAccordionConfig = {
      id: 'connectBankAccordionGroup',
      autoclose: true,
      spacing: 10,
      items: [
        { ...this.whyDoWeNeedThisInformationDisclosure },
        { ...this.whatHappensWhenIConnectDisclosure }
      ]
    };
  }

  public ngOnInit(): void {
    if (this.isFinicity) {
      this.finicitySuccessSubscription = this.finicityService
        .successSubscription()
        .subscribe((successObject: BankConnectSuccessObject) => {
          this.handleBankConnectionResponseService
            .callBankConnection(
              successObject,
              this.applicationFlowService.getContinuePath(),
              undefined,
              null,
              this.cmsData.checkingAccountModal
            )
            .then(() => {
              if (this.handleBankConnectionResponseService.achRoutingError) {
                setTimeout(() => window.scrollTo(0, 0));
                this.connectBankOptionConfig.buttons[0].disabled = 'true';
                this.form
                  .get('connectBankOption')
                  .setValue(ConnectBankEnumOptions.Manual);
              }
            });
        });
    } else {
      this.plaidService.initializePlaid();

      this.plaidSuccessSubscription = this.plaidService
        .successSubscription()
        .subscribe((successObject: BankConnectSuccessObject) => {
          this.handleBankConnectionResponseService.callBankConnection(
            successObject,
            this.applicationFlowService.getContinuePath(),
            undefined,
            this.cmsData.accountTypeModal,
            null
          );
        });
    }
    this.adaAccessibility.removeUnAllowedAriaAttributes();
  }

  public ngAfterViewInit(): void {
    if (this.newDesign) {
      const learnMoreLink = document.getElementById(
        'learnMoreLink'
      ) as HTMLAnchorElement;
      learnMoreLink.href = 'javascript:void(0);';
      learnMoreLink.addEventListener('click', () => {
        this.openLearnMoreModal();
      });
      const findBankLink = document.getElementById(
        'findBankLink'
      ) as HTMLAnchorElement;
      findBankLink.href = 'javascript:void(0);';
      findBankLink.addEventListener('click', () => {
        this.openFindBankModal();
      });
    }
  }

  public ngAfterViewChecked(): void {
    this.adaAccessibility.removeUnAllowedAriaAttributes();
  }

  public openLearnMoreModal(): void {
    const modalRef = this.modalService.open(DefaultModalComponent);
    modalRef.componentInstance.htmlContent = this.newDesign.learnModal;
  }

  public openFindBankModal(): void {
    const modalRef = this.modalService.open(DefaultModalComponent);
    modalRef.componentInstance.htmlContent = this.newDesign.findModal;
  }

  public ngOnDestroy(): void {
    if (this.isFinicity) {
      this.finicitySuccessSubscription.unsubscribe();
      this.finicityService.destroy();
    } else {
      this.plaidSuccessSubscription.unsubscribe();
      this.plaidService.destroy();
      this.continueSubscription?.unsubscribe();
    }

    if (this.formChangesSubscription != null) {
      this.formChangesSubscription.unsubscribe();
    }
  }

  public get formContainsConsents(): boolean {
    return ConsentHelper.formContainsConsents(this.form);
  }

  public submitConnectBank(): void {
    this.form.showValidationErrors();

    if (!this.form.valid) {
      return;
    }

    const option = this.form.get('connectBankOption').value;
    switch (option) {
      case ConnectBankEnumOptions.Manual:
        this.router.navigate(['bank-information']);
        break;

      case ConnectBankEnumOptions.Plaid:
        this.openPlaid();
        break;
      case ConnectBankEnumOptions.Finicity:
        this.finicityService.open();
        break;
      default:
        break;
    }
  }

  public debugHudSubmitConsents(): void {
    this.consentsComponent.debugHudSubmitAllConsents();
  }

  private openPlaid() {
    const thirdPartyDisclaimerOpenModal = this.modalService.open(
      ThirdPartyDisclaimerModalComponent,
      {
        modalDialogClass: 'third-party-disclosure-modal',
        centered: true
      }
    );
    this.continueSubscription =
    thirdPartyDisclaimerOpenModal.componentInstance.continueButtonClicked?.subscribe(
      (continuebuttonclicked: boolean) => {
        if (continuebuttonclicked == true) {
          this.plaidService.open();
        } else {
          return;
        }
      }
    );
  }

  public expand($event: number): void {
    this.googleAnalytics.expandAccordionEvent({
      link_id: this.groupAccordionConfig.items[$event].id,
      link_text: this.groupAccordionConfig.items[$event].head,

      link_classes: 'ecl-accordion-head',
      link_type: 'accordion',
      link_domain: this.locationProxy.origin,
      link_url: this.locationProxy.href
    });

    setTimeout(() => {
      const accordionClickAnchorTags = document.querySelectorAll(
        "[data-ga4='accordion-anchor-click']"
      );

      const anchorElements: Element[] = Array.from(accordionClickAnchorTags);

      for (let item of anchorElements) {
        item.addEventListener('click', () => {
          this.googleAnalytics.clickAccordionEvent({
            link_id: item.id,
            link_text: item.innerHTML,
            link_classes: item.className,
            link_type: 'accordion',
            link_domain: item.baseURI,
            link_url: item.attributes['href']?.value
          });
        });
      }
    }, 1000);
  }

  public collapse($event: number): void {
    this.googleAnalytics.collapseAccordionEvent({
      link_id: this.groupAccordionConfig.items[$event].id,
      link_text: this.groupAccordionConfig.items[$event].head,

      link_classes: 'ecl-accordion-head',
      link_type: 'accordion',
      link_domain: this.locationProxy.origin,
      link_url: this.locationProxy.href
    });
  }
}
