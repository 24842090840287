<div class="agreement-phrase" [formGroup]="formGroup">
  <div class="agreement-phrase-signature">
    <ecl-text-input
      class="agreement-phrase-input"
      [formControlConfig]="agreementPhraseConfig"
      [formControlName]="controlName"
    >
      <span [innerHtml]="label | safe: 'html'"></span>
    </ecl-text-input>
    <div class="agreement-phrase-check-mark">
      <i *ngIf="isValid" class="fa fa-check" aria-hidden="true"></i>
    </div>
  </div>
  <div
    *ngIf="instruction"
    class="agreement-phrase-instruction"
    [innerHtml]="instruction | safe: 'html'"
  ></div>
</div>
