import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  SecurityContext,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { ApplicationDataService } from '@application/application.service';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { ApplicationApi } from '@application/application.api';
import { shareReplay, take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import {
  distinctUntilChanged,
  pairwise,
  combineLatest,
  Subscription
} from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { RadioButtonComponent } from '@elevate/ui-components';
import {
  ApplicationData,
  ApplicationForm,
  FundMethodType
} from '@application/application';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import { RadioButton } from '@elevate/ui-components/lib/models/radio.model';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { ConsentSectionItem } from '@application/consents/consents.content';
import { ConsentsComponent } from '@application/consents/consents.component';
import { ConsentHelper } from '@application/consents/consent.helper';
import { PaymentMethodSelectionForm } from './payment-method-selection.form';
import { CmsPaymentMethodSelection } from './payment-method-selection.content';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import moment from 'moment';
import { CmsService } from '@core/cms';

interface FundingPaymentOption {
  formControlConfig: RadioButton;
  description: string;
  disclosure: string;
}

@Component({
  selector: 'app-payment-method-selection',
  templateUrl: './payment-method-selection.component.html',
  styleUrls: ['./payment-method-selection.component.scss'],
  providers: [PaymentMethodSelectionForm],
  encapsulation: ViewEncapsulation.None
})
export class PaymentMethodSelectionComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(RadioButtonComponent) radioButtons: QueryList<
    RadioButtonComponent
  >;
  @ViewChild('consentsComponent') consentsComponent: ConsentsComponent;

  public continueButton: string;
  public fundPaymentOptions: FundingPaymentOption[];
  private bankAccNoPlaceholder = '{checkingAccountLast4Digits}';
  private checkingAccountDigitsCount = 4;
  public consentsSection: ConsentSectionItem[] | ConsentSectionItem;
  public formChangesSubscription: Subscription;

  constructor(
    public form: PaymentMethodSelectionForm,
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private applicationApi: ApplicationApi,
    private applicationDataService: ApplicationDataService,
    private googleAnalytics: GoogleAnalytics,
    private cmsPageContentService: CmsPageContentService,
    private applicationFlowService: ApplicationFlowService,
    private cmsService: CmsService
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this.applicationApi.get(),
      this.route.data.pipe(take(1)),
      this.cmsService.getEntries('business_terms_glossary').pipe(shareReplay(1))
    ]).subscribe(
      ([appData, cmsData, buisnesTermsContent]: [
        ApplicationData,
        Data,
        any
      ]) => {
        const cmsPage = cmsData.cmsContent
          .paymentMethodSelection as CmsPaymentMethodSelection;
        const percentValue =
          buisnesTermsContent?.disclosureGroupsPayment?.disclosureGroupPayment?.find(
            element =>
              element.groupName === appData.form.shortAppOffer?.disclosureGroup
          )?.value ?? 'two percent (2%)';
        cmsPage.consentsSection = ConsentHelper.converToArray(
          cmsPage.consentsSection
        );
        cmsPage.consentsSection[0].consent.defaultConsent.disclosureModals = ConsentHelper.converToArray(
          cmsPage.consentsSection[0].consent.defaultConsent.disclosureModals
        );
        cmsPage.consentsSection[0].consent.defaultConsent.disclosureModals[0].content = ConsentHelper.converToArray(
          cmsPage.consentsSection[0].consent.defaultConsent.disclosureModals[0]
            .content
        );
        let text =
          cmsPage.consentsSection[0].consent.defaultConsent.disclosureModals[0]
            .content[0].disclosureText;
        text = text.replace('{percentValue}', percentValue);
        cmsPage.consentsSection[0].consent.defaultConsent.disclosureModals[0].content[0].disclosureText = text;

        this.form.build(cmsPage.validationMessages.requiredMessage);
        this.consentsSection = cmsPage.consentsSection;
        this.fillFundPaymentOptions(cmsPage);
        this.replaceBankAccNoPlaceholderWithData(appData);
        this.cmsPageContentService.updatePageTitle(cmsPage.header);
      }
    );
  }
  ngAfterViewInit(): void {
    this.radioButtons.changes.subscribe(comp => {
      comp
        .find(ele => ele.value === 'Electronic')
        .radiobutton.nativeElement.click();
    });
    this.formChangesSubscription = this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
        pairwise() // gets a pair of old and new value
      )
      .subscribe(([oldValue, newValue]) => {
        this.googleAnalytics.logFormInteractionEventsForChangedValues(
          oldValue,
          newValue
        );
      });
  }

  public get displayConsentSection(): boolean {
    return (
      Array.isArray(this.consentsSection) && this.consentsSection.length > 1
    );
  }

  public selectValue(paymentMethod: string): void {
    const autopay = paymentMethod === FundMethodType.Electronic ? true : false;
    this.form.patchValue({ paymentMethod });
    this.form.patchValue({ autopay });
    this.radioButtons.forEach(element => {
      if (paymentMethod === element.value) {
        element.radioValue = 'true';
        element.radiobutton.nativeElement.click();
      }
    });
  }

  public onSubmit(): void {
    this.form.showValidationErrors();

    if (!this.form.valid) {
      return;
    }

    if (this.form.value.autopay) {
      const modal = this.consentsComponent.openModal(
        (
          this.consentsSection[0] ??
          (this.consentsSection as ConsentSectionItem)
        ).consent
      );

      modal.consentModal?.content?.forEach(modalContent => {
        modalContent.disclosureText = this.replaceModalVariables(
          modalContent.disclosureText
        );
      });

      modal.submitClick?.subscribe(() => {
        this.submitPage();
      });
      modal.cancelClick?.subscribe(() => {
        this.form.patchValue({ paymentMethod: null });
        this.form.patchValue({ autopay: false });
        this.radioButtons.forEach(element => {
          element.radioValue = null;
          element.radiobutton.nativeElement.checked = false;
        });
        this.form.markAsUntouched();
      });
    } else {
      this.submitPage();
    }
  }

  private submitPage(): void {
    const applicationForm: ApplicationForm = {
      paymentMethod: this.form.value.paymentMethod as FundMethodType,
      autopay: this.form.value.autopay,
      continuePath: this.applicationFlowService.getContinuePath(),
      disclosures: this.consentsComponent?.disclosures
    };

    this.applicationApi
      .append(applicationForm)
      .subscribe((response: HttpResponse<any>) => {
        if (response.status === 204) {
          this.applicationDataService.merge({ form: applicationForm });
          this.router.navigate([applicationForm.continuePath]);
        }
      });
  }

  private fillFundPaymentOptions(cmsPage: CmsPaymentMethodSelection): void {
    this.continueButton = cmsPage.buttons.continue;
    this.fundPaymentOptions = cmsPage.selectOptions.map(
      option =>
        ({
          formControlConfig: {
            id: `${option.value}Option`,
            name: 'paymentMethod',
            label: this.sanitizeHtml(
              `<div class="payments-checkboxlabel-header">${option.name}</div>`
            ),
            value: option.value,
            attributes: {
              'data-nid-target': `fundingType${option.value}Option`
            }
          },
          description: this.sanitizeHtml(option.content),
          disclosure: this.sanitizeHtml(option.disclosure)
        } as FundingPaymentOption)
    );
  }

  private replaceModalVariables(text: string): string {
    text = text?.replace(
      '{achAgreementDate}',
      moment()
        .tz('America/Chicago')
        .format('MM/DD/YYYY')
    );
    text = text?.replace(
      '{authorizePaymentDateTime}',
      moment().format('MM/DD/YYYY h:mm:ss a')
    );
    const appData = this.applicationDataService.getApplication();
    const selectedBankAcct = appData.form.applicant.bank.accounts.find(
      (obj: any) => {
        return obj.key === appData.form.applicant.bank.draftFromKey;
      }
    );
    return text?.replace(
      '{bankAccountLast4}',
      selectedBankAcct.accountNumber.slice(-this.checkingAccountDigitsCount)
    );
  }

  private replaceBankAccNoPlaceholderWithData(appData: ApplicationData): void {
    const selectedBankAcct = appData.form.applicant.bank.accounts.find(
      (obj: any) => {
        return obj.key === appData.form.applicant.bank.draftFromKey;
      }
    );
    const electronicOption = this.fundPaymentOptions.find(_ =>
      _.description.includes(this.bankAccNoPlaceholder)
    );

    electronicOption.description = electronicOption.description.replace(
      this.bankAccNoPlaceholder,
      selectedBankAcct.accountNumber.slice(-this.checkingAccountDigitsCount)
    );
  }

  private sanitizeHtml(html: string): string {
    return this.sanitizer.sanitize(SecurityContext.STYLE, html);
  }

  public get formContainsConsents(): boolean {
    return ConsentHelper.formContainsConsents(this.form);
  }

  public debugHudSubmitConsents(): void {
    this.consentsComponent.debugHudSubmitAllConsents();
  }
  public async ngOnDestroy(): Promise<void> {
    if (this.formChangesSubscription != null) {
      this.formChangesSubscription.unsubscribe();
    }
  }
}
