import { Injectable } from '@angular/core';

export enum SessionStorageKeys {
  authorizationToken = 'authorizationToken',
  showcookieMessage = 'showcookieMessage',
  applicationGCID = 'applicationGCID'
}

export enum SessionStorageObjects {
  bankAccountsInfo = 'bankAccountsInfo',
  selectedPaymentOption = 'selectedPaymentOption',
  queryParameters = 'queryParameters',
  googleTagManagerVariables = 'gtagManagerVariables',
  emailOnlyAttempts = 'emailOnlyAttempts',
  stateOptions = 'stateOptions',
  coBrowseEnabledFlag = 'coBrowseEnabledFlag',
  applicationFlows = 'applicationFlows'
}

@Injectable({ providedIn: 'root' })
export class SessionStorageService {
  public setItem(key: SessionStorageKeys, value: any): void {
    sessionStorage.setItem(key, value);
  }

  public getItem(key: SessionStorageKeys): any {
    return sessionStorage.getItem(key);
  }

  public deleteItem(key: SessionStorageKeys): void {
    sessionStorage.removeItem(key);
  }

  public setObject(key: SessionStorageObjects, value: any): void {
    const jsonObj = JSON.stringify(value);
    sessionStorage.setItem(key, jsonObj);
  }

  public getObject(key: SessionStorageObjects): any {
    const sessionString = sessionStorage.getItem(key);
    return JSON.parse(sessionString);
  }

  public deleteObject(key: SessionStorageObjects): void {
    sessionStorage.removeItem(key);
  }

  public clearAll(): void {
    sessionStorage.clear();
  }
}
