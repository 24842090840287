<div class="choose-your-terms-page-container">
  <div class="choose-your-terms-subheader-container">
    <p *ngIf="isCounterOffer" [innerHtml]="counterOfferSubText | safeHtml"></p>
    <p [innerHtml]="subHeader | safeHtml"></p>
  </div>
  <div class="choose-your-terms-offer-container">
    <form [formGroup]="form">
      <article class="choose-your-terms-section-container row">
        <section
          class="loan-amount-section col-sm choose-your-terms-card-section"
        >
          <header class="choose-your-terms-offer-section-title">
            {{ content.loanAmountBlock.title }}
          </header>
          <div class="loading-spinner-placement" *ngIf="loanAmountLoading">
            <ecl-spinner size="lg"></ecl-spinner>
          </div>
          <div class="loan-amount-input-container" *ngIf="!loanAmountLoading">
            <ecl-input-currency
              formControlName="loanAmount"
              [formControlConfig]="loanAmountConfig"
              googleAnalyticsFocusOut="loanAmount"
              (blur)="updateLoanAmount()"
            ></ecl-input-currency>
            <p class="loan-amount-input-subtext small">
              {{ inputLoanAmountSubtext }}
            </p>
            <div class="choose-your-terms-button-container">
              <p class="loan-amount-button-subtext small">
                {{ loanAmountAPR }}%
              </p>
            </div>
          </div>
        </section>
        <section class="col-sm choose-your-terms-card-section">
          <header class="choose-your-terms-offer-section-title">
            {{ content.paymentOptionBlock.title }}
          </header>

          <div *ngIf="optionsLoading" class="loading-spinner-placement">
            <ecl-spinner size="lg"></ecl-spinner>
          </div>
          <div *ngIf="!optionsLoading">
            <p class="colored-text selected-payment-text">
              {{
                selectedPaymentAmount || 0 | currency: 'USD':'symbol':'1.2-2'
              }}
            </p>
            <ecl-radio-group
              [formControlConfig]="paymentOptionConfig"
              formControlName="paymentOptions"
              (change)="updateSelectedAmount($event)"
            >
              <ecl-radio-button
                *ngFor="let option of buttons; let btnIndex = index"
                [formControlConfig]="buttons[btnIndex]"
              ></ecl-radio-button>
            </ecl-radio-group>
          </div>
        </section>
      </article>
    </form>
  </div>
  <div class="choose-your-terms-schedule-container">
    <h2 class="header-scroll-target">
      {{ content.paymentScheduleBlock.header }}
    </h2>
    <div *ngIf="tableData">
      <p
        *ngIf="selectedPaymentAmount"
        [innerHtml]="firstPaymentSubText | safeHtml"
      ></p>
      <div
        class="section-header ecl-font-section-header-color-xsmall"
        id="confirmLabel"
      ></div>
      <ecl-table
        [config]="tableConfig"
        [pageSize]="entriesLength"
        [tableData]="tableData"
      >
      </ecl-table>
      <div
        (click)="updateTableSize()"
        class="table-expansion-button ecl-font-link-light-bg"
      >
        {{ content.fullPaymentScheduleText }}
        <i
          [ngClass]="extendTable ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"
          aria-hidden="true"
        ></i>
      </div>
    </div>
  </div>
  <div class="btn-container">
    <button
      focusOnError
      id="chooseYourTermsContinueButton"
      type="submit"
      (click)="onSubmit()"
      class="ecl-button-primary"
      googleAnalyticsClick="choose-your-terms-Continue"
    >
      {{ content.submitButtonLabel }}
    </button>
  </div>
</div>
