import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthTokenInterceptor } from '@core/auth-token-interceptor/auth-token.interceptor';
import { AuthValidationInterceptor } from '@core/auth-validation-interceptor/auth-validation.interceptor';
import { EclFormsModule } from '@elevate/forms';
import { EclUIComponentsModule } from '@elevate/ui-components';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { BankInformationComponent } from './bank-information/bank-information.component';
import { CheckingOptionsComponent } from './checking-options/checking-options.component';
import { ConnectBankMoreInformationComponent } from './connect-bank-more-information/connect-bank-more-information.component';
import { AccountTypeModalComponent } from './connect-bank/account-type-modal/account-type-modal.component';
import { ConnectBankComponent } from './connect-bank/connect-bank.component';
import { DebugHudComponent } from './debug-hud/debug-hud.component';
import { ElasticSpinnerComponent } from './elastic-spinner/elastic-spinner.component';
import { ErrorComponent } from './error/error.component';
import { FundingPaymentSelectionComponent } from './funding-payment-selection/funding-payment-selection.component';
import { GettingStartedComponent } from './getting-started/getting-started.component';
import { ReCaptchaComponent } from './getting-started/reCaptcha/recaptcha.component';
import { ResumeModalComponent } from './getting-started/resume-modal/resume-modal.component';
import { InReviewComponent } from './underwriting-decisions/in-review/in-review.component';
import { IncomeVerificationComponent } from './income-verification/income-verification.component';
import { IncomeComponent } from './income/income.component';
import { EmployerComponent } from './income/sources/components/employer/employer.component';
import { PaymentFrequencyComponent } from './income/sources/components/payment-frequency/payment-frequency.component';
import { JobIncomeComponent } from './income/sources/job-income/job-income.component';
import { OtherComponent } from './income/sources/other/other.component';
import { RetirementComponent } from './income/sources/retirement/retirement.component';
import { SelfEmployedComponent } from './income/sources/self-employed/self-employed.component';
import { SocialSecurityComponent } from './income/sources/social-security/social-security.component';
import { UnemploymentComponent } from './income/sources/unemployment/unemployment.component';
import { LoadingModalComponent } from './loading-modal/loading-modal.component';
import { PendingComponent } from './pending/pending.component';
import { PrequalApprovalComponent } from './prequalification-decisions/prequal-approval/prequal-approval.component';
import { RestrictNavigationModalComponent } from './restrict-navigation/restrict-navigation-modal/restrict-navigation-modal.component';
import { UnderwritingApprovalComponent } from './underwriting-decisions/underwritng-approval/underwriting-approval.component';
import { VerifyInfoComponent } from './verify-info/verify-info.component';
import { FocusOnErrorDirective } from '@core/directives/focus-on-error.directive';
import { AdditionalInfoBankComponent } from './underwriting-decisions/additional-info-bank/additional-info-bank.component';
import { UsePreviousAccountModalComponent } from './underwriting-decisions/additional-info-bank/use-previous-account-modal/use-previous-account-modal.component';
import { DeclineComponent } from './decline-page/decline.component';
import { MarriedComponent } from './underwriting-decisions/underwritng-approval/married-form/married.component';
import { AdditionalInfoComponent } from './underwriting-decisions/additional-info/additional-info.component';
import { StateMessageModalComponent } from './getting-started/state-message-modal/state-message-modal.component';
import { SignAndSubmitComponent } from './sign-and-submit/sign-and-submit.component';
import { AccountSetupComponent } from './account-setup/account-setup.component';
import { SignSubmitDisclosuresModalComponent } from './sign-and-submit/sign-submit-disclosures-modal/sign-submit-disclosures-modal.component';
import {
  DynamicCheckboxComponent,
  DINAMIC_CHECKBOX_SELECTOR
} from './sign-and-submit/sign-submit-disclosures-modal/dynamic-checkbox/dynamic-checkbox.component';
import { DynamicHTMLModule } from '@core/dynamic-html/dynamic-html.module';
import { UserInactivityModule } from '@core/inactivity-module/user-inactivity.module';
import {
  DINAMIC_LAST4SSN_SELECTOR,
  DynamicLast4ssnComponent
} from './sign-and-submit/sign-submit-disclosures-modal/dynamic-last4ssn/dynamic-last4ssn.component';
import { InputCurrencyFormatterDirective } from '@core/directives/input-currency-formatter/input-currency-formatter.directive';
import { BookingComponent } from './booking/booking.component';
import { GoogleAnalyticsModule } from '@core/google-analytics/googleanalytics.module';
import { ReapplyComponent } from './getting-started/reapply/reapply.component';
import { PartnerVerificationModalComponent } from './partner/partner-verification-modal/partner-verification-modal.component';
import { PartnerLandingComponent } from './partner/partner-landing-page/partner-landing.component';
import { EclAddressFormModule } from '@elevate/address';
import { EclPasswordModule } from '@elevate/password';
import { FinalPageComponent } from './final-page/final-page.component';
import { EclLayoutModule } from '@elevate/layout';
import { EclBaseModalModule } from '@elevate/base-modal';
import { EclTableModule } from '@elevate/table';
import { EclDatePickerModule } from '@elevate/date-picker';
import { ConsentsComponent } from './consents/consents.component';
import { DisclosureModalComponent } from './consents/disclosure-modal/disclosure-modal.component';
import { TypedNameComponent } from './consents/e-signature/typed-name/typed-name.component';
import { Last4SSNComponent } from './consents/e-signature/last-4-ssn/last-4-ssn.component';
import { AgreementPhraseComponent } from './consents/e-signature/agreement-phrase/agreement-phrase.component';
import { CheckboxComponent } from './consents/e-signature/checkbox/checkbox.component';
import { StateNotServicedModalComponent } from './getting-started/state-not-serviced-modal/state-not-serviced-modal.component';
import { NoOptionModalComponent } from './getting-started/no-option-modal/no-option-modal.component';
import { GoToSigninModalComponent } from './getting-started/goto-signin-modal/goto-signin-modal.component';
import { RedirectToLegacyModalComponent } from './getting-started/redirect-to-legacy-modal/redirect-to-legacy-modal.component';
import { ProcessingDocsComponent } from './processing-docs/processing-docs.component';
import { DocumentUploadPageComponent } from './document-upload-page/document-upload-page.component';
import { EclDocumentUploadModule } from '@elevate/document-upload';
import { PartnerVerificationService } from '@core/partner/partner-verification.service';
import { PartnerVerificationServiceMock } from '@core/partner/partner-verification-mock.service';
import { PARTNER_VERIFICATION_SERVICE } from '@core/partner/partner-verification.interface';
import { Environment } from '@environment/environment';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AffectCreditScoreModalComponent } from './getting-started/affect-credit-score-modal/affect-credit-score-modal.component';
import { MailingAddressComponent } from './getting-started/mailing-address/mailing-address.component';
import { PaymentMethodSelectionComponent } from './payment-method-selection/payment-method-selection.component';
import { CheckingAccountModalComponent } from './connect-bank/checking-account-modal/checking-account-modal.component';
import { DefaultModalComponent } from '@core/default-modal/default-modal.component';
import { SafeHtmlPipe } from '@core/directives/safe-html.pipe';
import { NoiaComponent } from './underwriting-decisions/notice-of-incomplete-action/notice-of-incomplete-action.component';
import {EclUsernameModule} from '@elevate/username';
import { ChooseYourTermsComponent } from './underwriting-decisions/choose-your-terms/choose-your-terms.component';
import { DocumentHtmlContentComponent } from './consents/document-html-content/document-html-content.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    EclUsernameModule,
    EclFormsModule,
    EclUIComponentsModule,
    EclAddressFormModule,
    EclPasswordModule,
    EclLayoutModule,
    EclBaseModalModule,
    EclTableModule,
    EclDatePickerModule,
    EclDocumentUploadModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    UserInactivityModule,
    GoogleAnalyticsModule.forRoot(),
    DynamicHTMLModule.forRoot({
      components: [
        {
          component: DynamicCheckboxComponent,
          selector: DINAMIC_CHECKBOX_SELECTOR
        },
        {
          component: DynamicLast4ssnComponent,
          selector: DINAMIC_LAST4SSN_SELECTOR
        }
      ]
    }),
    EclLayoutModule,
    NgScrollbarModule
  ],
  declarations: [
    FocusOnErrorDirective,
    ConsentsComponent,
    ReapplyComponent,
    GettingStartedComponent,
    MarriedComponent,
    VerifyInfoComponent,
    ReCaptchaComponent,
    DebugHudComponent,
    ResumeModalComponent,
    PartnerVerificationModalComponent,
    IncomeComponent,
    ConnectBankComponent,
    BankInformationComponent,
    EmployerComponent,
    JobIncomeComponent,
    SelfEmployedComponent,
    ConnectBankComponent,
    PaymentFrequencyComponent,
    SocialSecurityComponent,
    CheckingOptionsComponent,
    InputCurrencyFormatterDirective,
    ErrorComponent,
    RetirementComponent,
    OtherComponent,
    UnemploymentComponent,
    ElasticSpinnerComponent,
    LoadingModalComponent,
    FundingPaymentSelectionComponent,
    PaymentMethodSelectionComponent,
    IncomeVerificationComponent,
    PrequalApprovalComponent,
    UnderwritingApprovalComponent,
    DeclineComponent,
    NoiaComponent,
    AccountTypeModalComponent,
    ConnectBankMoreInformationComponent,
    RestrictNavigationModalComponent,
    PendingComponent,
    InReviewComponent,
    AdditionalInfoComponent,
    AdditionalInfoBankComponent,
    UsePreviousAccountModalComponent,
    StateMessageModalComponent,
    StateNotServicedModalComponent,
    NoOptionModalComponent,
    SignAndSubmitComponent,
    GoToSigninModalComponent,
    RedirectToLegacyModalComponent,
    SignSubmitDisclosuresModalComponent,
    AccountSetupComponent,
    DynamicCheckboxComponent,
    DynamicLast4ssnComponent,
    BookingComponent,
    PartnerLandingComponent,
    FinalPageComponent,
    DisclosureModalComponent,
    Last4SSNComponent,
    TypedNameComponent,
    AgreementPhraseComponent,
    CheckboxComponent,
    DocumentHtmlContentComponent,
    ProcessingDocsComponent,
    DocumentUploadPageComponent,
    AffectCreditScoreModalComponent,
    MailingAddressComponent,
    CheckingAccountModalComponent,
    DefaultModalComponent,
    ChooseYourTermsComponent,
    SafeHtmlPipe
  ],
  providers: [
    { provide: 'window', useValue: window },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthValidationInterceptor,
      multi: true
    },
    {
      provide: PARTNER_VERIFICATION_SERVICE,
      useFactory: (environment: Environment, httpClient: HttpClient) => {
        return environment.developerMode
          ? new PartnerVerificationServiceMock()
          : new PartnerVerificationService(environment, httpClient);
      },
      deps: [Environment, HttpClient]
    }
  ]
})
export class ApplicationModule {}
