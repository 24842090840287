import { DocumentTypeGenerated } from "@core/document/document.api";

export interface ConsentModalAgreement {
  type: string;
  label: string;
  name: string;
  instruction: string;
  validationRequired: string;
  validationNotMatch: string;
  ignoreSpaces?: boolean;
  ignoreCase?: boolean;
  checkPhrase?: string;
  outOfScroll?: boolean;
}

export enum DocumentType {
  LoanAgreement= 'LoanAgreement',
  CreditScoreDisclosure='CreditScoreDisclosure'
}

export enum SignatureType {
  Last4Ssn = 'Last 4 SSN',
  TypedName = 'Typed Name',
  AgreementPhrase = 'Agreement Phrase',
  CheckBox = 'CheckBox'
}

export interface SignatureModel {
  signatureType: SignatureType;
  isConsentGiven: boolean;
  signatureValue?: string | boolean;
  disclosureName?: string;
  controlName?: string;
}

export interface DisclosureModalEmitDataOnSubmit {
  signatureModel: SignatureModel[];
  documentTypeGenerated: DocumentTypeGenerated[];

}

export class SignatureHelper {
  public static getSignatureControlName(
    agreement: ConsentModalAgreement
  ): string {
    return agreement?.name?.length > 0 ? agreement.name : 'consentAgreement';
  }
}
