import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  SecurityContext,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import {
  AbTestingData,
  ApplicationData,
  ApplicationFlow,
  ApplicationForm,
  ShortAppOffer
} from '@application/application';
import {
  ApplicationApi,
  StartOption,
  StartOptionsRequest,
  StartOptionsResponse
} from '@application/application.api';
import { ApplicationDataService } from '@application/application.service';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import { CookieService, CookieEnums } from '@core/cookie/cookie-service';
import {
  SessionStorageKeys,
  SessionStorageService
} from '@core/session-storage/session-storage.service';
import { StateMessageService } from '@core/state-message/state-message-service';
import { AutocompleteOption } from '@elevate/ui-components';
import { Environment } from '@environment/environment';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import * as deepDiff from 'deep-diff';
import moment from 'moment';
import {
  lastValueFrom,
  Subscription,
  distinctUntilChanged,
  pairwise,
  take
} from 'rxjs';
import { GettingStartedDebugService } from './debug-hud/debug-hud.service';
import { GettingStarted } from './getting-started';
import { GettingStartedFormGroup } from './getting-started.form';
import * as gettingStartedFormConfig from './getting-started.form.config';
import { GettingStartedValidator } from './getting-started.validator';
import { GoToSigninModalComponent } from './goto-signin-modal/goto-signin-modal.component';
import { NoOptionModalComponent } from './no-option-modal/no-option-modal.component';
import { ReCaptchaService } from './reCaptcha/recaptcha.service';
import { RedirectToLegacyModalComponent } from './redirect-to-legacy-modal/redirect-to-legacy-modal.component';
import { ResumeModalComponent } from './resume-modal/resume-modal.component';
import { StateMessageModalComponent } from './state-message-modal/state-message-modal.component';
import {
  ECCustomDateParserFormatter,
  ECCustomDateAdapter
} from '@core/datepicker/EDCustomDatepickerAdapter.service';
import {
  GoogleAnalytics,
  GoogleTagData,
  GoogleTagManagerVariables
} from '@core/google-analytics/googleanalytics.service';
import { UserInactivityStatusService } from '@core/inactivity-module/user-inactivity.service';
import { GettingStartedBaseComponent } from './getting-started-base.component';
import { StateEligibilityService } from './state-eligibility/state-eligibility.service';
import { AppInsightsService } from '@core/app-insights/app-insights.service';

import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { ConsentsComponent } from '@application/consents/consents.component';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import { ValidationMessagesError } from '@elevate/forms';
import { TodayCardHandleService } from '@core/brand-handle-services/today-card-handle.service';
import { CurrencyPipe } from '@angular/common';
import { ConfigurationService } from '@core/configuration/configuration.service';
import { NeuroIdService } from '@core/neuro-id/neuro-id.service';
import {
  LogRocketService,
  AMSAppSessionCorrelator
} from '@core/logrocket/logrocket.service';

@Component({
  selector: 'app-apply-home',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss'],
  providers: [
    GettingStartedFormGroup,
    GettingStartedDebugService,
    GettingStartedValidator,
    {
      provide: NgbDateAdapter,
      useClass: ECCustomDateAdapter
    },
    {
      provide: NgbDateParserFormatter,
      useClass: ECCustomDateParserFormatter
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class GettingStartedComponent extends GettingStartedBaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('consentsComponent') private consentsComponent: ConsentsComponent;
  @ViewChild('consentToCommunicationComponent')
  private consentToCommunicationComponent: ConsentsComponent;
  public showDebugHud = 'gettingStarted';
  public pageSubTitle: string;
  public pageContent: SafeHtml;
  public routeSubscription: Subscription;
  public formConfig = gettingStartedFormConfig;
  public resumeCookie: string;
  public isPartnerPrePop = false;
  public recaptchaToken: string;
  private nextPage: string;
  private shortAppOfferRequiredFields = [
    'firstname',
    'lastname',
    'email',
    'state',
    'code',
    'creditLimit',
    'annualFee',
    'purchaseAPR',
    'transferAPR'
  ];
  private resumeCanceled: EventEmitter<boolean> = new EventEmitter<boolean>();

  public gcid: string;
  public audId: string;

  public subId: string;
  public formChangesSubscription: Subscription;

  public abTestingFromRedirect?: AbTestingData[];

  constructor(
    public form: GettingStartedFormGroup,
    public debugHud: GettingStartedDebugService,
    public modalService: NgbModal,
    public stateMessageService: StateMessageService,
    private reCaptchaService: ReCaptchaService,
    private sanitizer: DomSanitizer,
    public route: ActivatedRoute,
    private applicationDataService: ApplicationDataService,
    private router: Router,
    private applicationApi: ApplicationApi,
    public environment: Environment,
    private sessionStorageService: SessionStorageService,
    private loadingService: LoadingModalService,
    private cookieService: CookieService,
    public googleAnalytics: GoogleAnalytics,
    private userAppStarted: UserInactivityStatusService,
    private neuroIdService: NeuroIdService,
    public validators: GettingStartedValidator,
    public stateEligibilityService: StateEligibilityService,
    public appInsightsService: AppInsightsService,
    public pageHeaderService: CmsPageContentService,
    public applicationFlowService: ApplicationFlowService,
    public currencyPipe: CurrencyPipe,
    public todayCardHandleService: TodayCardHandleService,
    @Inject('window') private window: Window,
    public configurationService: ConfigurationService,
    private activatedRoute: ActivatedRoute,
    private logrocketService: LogRocketService
  ) {
    super(
      form,
      modalService,
      stateMessageService,
      validators,
      stateEligibilityService,
      googleAnalytics,
      appInsightsService,
      environment,
      route,
      todayCardHandleService,
      configurationService,
      currencyPipe
    );
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.resumeCookie = this.cookieService.get(CookieEnums.ResumeByAppId);
    this.cmsPageContent = this.route.snapshot.data.cmsContent.prequalificationGettingStarted.gettingStartedCommon;
    this.form.build(this.cmsPageContent.validationMessages);
    const dateRangeMessage = this.cmsPageContent.validationMessages.dateOfBirth
      .otherErrorMessage;
    this.formConfig.emailConfig.disabled = 'false';
    this.formConfig.ssnConfig.disabled = 'false';
    this.formConfig.dobConfig.errorDateRange = dateRangeMessage
      ? dateRangeMessage
      : this.formConfig.dobConfig.errorDateRange;

    this.pageSubTitle = this.cmsPageContent.subHeader;
    this.pageContent = this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.cmsPageContent.content
    );

    this.activatedRoute.queryParams.subscribe(async (params: Params) => {
      this.gcid = params['gcid'];
      this.audId = params['aud_id'];
      this.subId = params['subid'];
      this.sessionStorageService.setItem(
        SessionStorageKeys.applicationGCID,
        this.gcid
      );

      this.googleAnalytics.setOneTimeGlobalData();
    });

    if (this.environment.brand === 'Today') {
      this.populateShortAppData();
      this.fillVariablesInCmsOfferDetails();
    }

    if (this.resumeCookie) {
      this.callStartOptionById(this.resumeCookie);
    }

    this.populateData();
    this.pageHeaderService.updatePageTitle(this.cmsPageContent.header);
  }

  public async ngAfterViewInit(): Promise<void> {
    this.formChangesSubscription = this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
        pairwise(), // gets a pair of old and new value
        take(1)
      )
      .subscribe(([oldValue, newValue]) => {
        this.googleAnalytics.logFormInteractionEventsForChangedValues(
          oldValue,
          newValue,
          false
        );
      });

    setTimeout(() => {
      this.googleAnalytics.formViewEvent();
      const logRocketData: AMSAppSessionCorrelator = {
        appInsightsSessionId: this.appInsightsService.getGoogleTagAppInsightsTelemetry()
          .appInsightsSesssionId,
        ga4SessionId: this.cookieService.getSessionId()
      };
      this.logrocketService.trackAppLogRocketCustomEvent(logRocketData);
      const gtagData: GoogleTagManagerVariables = {
        aud_id: this.audId,
        gcid: this.gcid,

        sub_id: this.subId,
        full_referral_url: document.referrer,
        userType: ApplicationFlow.OrganicPrequal,
        visitorid: this.appInsightsService.getAppInsightsAccountId(),
        neuroId: this.neuroIdService.neuroIdSessionId,
        logrocket_id: this.cookieService.getSessionId()
      };

      this.googleAnalytics.setGoogleTagManagerVariables(gtagData, true);
    }, 3000);
  }
  public async onFocusOut(event: any): Promise<void> {
    this.googleAnalytics.formInteractionEvent({
      field_id: event.target.id,
      step_name: window.location.pathname
    });
  }

  public onAddressSuggestionError(event: any) {
    this.appInsightsService.trackException(event);
  }

  public async submit(): Promise<void> {

    if(!this.applicationFlowService.getApplicationFlows())
      await this.applicationFlowService.setApplicationFlows();
    
    this.nextPage = this.applicationFlowService.getContinuePath(
      null,
      ApplicationFlow.OrganicPrequal
    );

    if (super.isStateNotEligible()) {
      return;
    }
    this.form.showValidationErrors();

    if (!this.form.valid) {
      return;
    }

    const existingData = this.createGettingStartedData(
      this.applicationDataService.getApplication()?.form
    );

    if (!existingData) {
      this.loadingService.open();
      await this.callStartOptions();
    } else {
      this.loadingService.open();
      const formValue = this.form.value;
      const diff = deepDiff(existingData, formValue);

      if (!diff) {
        this.router.navigate([this.nextPage]);
      } else if (diff.some(item => ['ssn', 'email'].includes(item.path[0]))) {
        await this.callStartOptions(existingData);
      } else {
        const formData = this.createApplicationRequest(formValue, diff);
        this.appendDisclosuresToFormData(formData);
        this.applicationDataService.replaceApplicationForm({ form: formData });
        await lastValueFrom(this.applicationApi.append(formData)).then(() => {
          this.router.navigate([this.nextPage]);
        },(error: any) =>{
          if(error.error.message.includes('is not Serviced')) {
            this.loadingService.close();
            this.isStateEligible = false;
            this.isStateNotEligible();
          }
        });
      }
    }
  }

  private appendDisclosuresToFormData(formData: ApplicationForm): void {
    if (formData.disclosures == null) {
      formData.disclosures = [];
    }

    formData.disclosures = formData.disclosures.concat(
      this.consentToCommunicationComponent?.disclosures
    );
    if (this.consentsComponent) {
      formData.disclosures = formData.disclosures.concat(
        this.consentsComponent?.disclosures
      );
    }
    formData.applicant.electronicCommunicationAgreement = this.consentToCommunicationComponent?.disclosures?.some(
      disc => disc.consentGiven === true
    );
  }

  public setCityAndState(option: AutocompleteOption): void {
    const city = this.formConfig.cityMap[option.id].city;
    const state = this.formConfig.cityMap[option.id].state;
    this.form.patchValue({ city });
    this.form.patchValue({ state });
  }

  private async callStartOptions(existingData?: GettingStarted): Promise<void> {
    let startOptionResponse: StartOptionsResponse;

    const startOptionsRequest: StartOptionsRequest = {
      brand: this.environment.brand,
      applicationFlow: 'OrganicPrequal',
      socialSecurityNumber: this.form.get('ssn').value,
      email: this.form.get('email').value,
      stateCode: this.form.get('state').value,
      dateOfBirth: this.form.get('dob').value
    };

    if (this.debugHud.mockStartOption === 'Call Api') {
      startOptionResponse = await lastValueFrom(
        this.applicationApi.startOptions(startOptionsRequest)
      );
    } else {
      startOptionResponse = this.debugHud.getMockApiResponse();
      const authToken = this.sessionStorageService.getItem(
        SessionStorageKeys.authorizationToken
      );
      if (authToken) {
        const data: ApplicationData = await lastValueFrom(
          this.applicationApi.get()
        );
        startOptionResponse.applicationId = data.id;
      }
    }

    switch (startOptionResponse.startOptions) {
      case StartOption.RedirectToLegacy:
        const redirectContent = this.route.snapshot.data.cmsContent
          .prequalificationGettingStarted.redirectToLegacy;
        if (redirectContent) {
          this.loadingService.close();
          const appData: any = {
            firstName: existingData?.firstname || '',
            lastName: existingData?.lastname || '',
            zipCode: existingData?.postalCode || '',
            state: existingData?.state || '',
            email: existingData?.email || '',
            mobilePhoneNumber: existingData?.mobile || '',
            directMailCode: existingData?.code || '',
            abTesting: startOptionResponse.abTesting || []
          };
          if (appData.abTesting?.length) {
            this.googleAnalytics.setGoogleTagManagerVariables(
              {
                abTesting: appData.abTesting
              },
              true
            );
          }
          const encodedAppData = encodeURIComponent(btoa(JSON.stringify(appData)));
          const redirectToLegacyModalRef = this.modalService.open(
            RedirectToLegacyModalComponent,
            {
              backdrop: 'static',
              keyboard: false
            }
          );
          redirectToLegacyModalRef.componentInstance.htmlContent = redirectContent.modal;
          redirectToLegacyModalRef.result.then(result => {
            this.window.location.href = `${startOptionResponse.redirectUrl}?redirect=${encodedAppData}`;
          });
          setTimeout(() => {
            redirectToLegacyModalRef.close();
          }, redirectContent.duration);
        }
        return;
      case StartOption.NoOptions:
        this.loadingService.close();
        const noOptionModalRef = this.modalService.open(NoOptionModalComponent);
        noOptionModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.noOptionModal;
        return;
      case StartOption.RedirectToBau:
      case StartOption.RedirectFormerRefinanceSignIn:
        this.loadingService.close();
        const goToModalRef = this.modalService.open(GoToSigninModalComponent, {
          backdrop: 'static',
          windowClass: 'goto-signin-modal'
        });
        goToModalRef.componentInstance.redirectionReason = startOptionResponse.startOptions.toString();
        goToModalRef.componentInstance.userEmail = this.form.get('email').value;
        goToModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.goToSigninModal;
        return;
      case StartOption.StartNewOnly:
        this.createApplication();
        return;
      case StartOption.ResumeOrStartNew:
      case StartOption.ResumeOnly: {
        this.loadingService.close();
        const resumeModalRef = this.modalService.open(ResumeModalComponent, {
          backdrop: 'static',
          windowClass: 'resume-modal'
        });
        resumeModalRef.componentInstance.data = {
          ...startOptionResponse,
          firstName: this.form.value.firstname
        };
        resumeModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.resumeModal;
        resumeModalRef.result.then(data => {
          if (data && !data.resumeApplication) {
            this.createApplication();
          }
        });
        return;
      }
    }
  }

  private async createApplication(): Promise<void> {
    if (
      this.sessionStorageService.getItem(
        SessionStorageKeys.authorizationToken
      ) !== null
    ) {
      this.appendApplicationData();
      return;
    }

    this.loadingService.close();
    this.recaptchaToken = this.debugHud.disableReCaptcha
      ? 'skip-recaptcha'
      : await lastValueFrom(this.reCaptchaService.execute());
    this.loadingService.open();

    const formData = this.createApplicationRequest(this.form.value);
    this.appendDisclosuresToFormData(formData);
    let isSuccessfull = false;

    await lastValueFrom(
      this.applicationApi.create({
        brand: this.environment.brand,
        applicationFlow: 'OrganicPrequal',
        form: formData,
        recaptchaToken: this.recaptchaToken,
        abTesting: this.abTestingFromRedirect
      })
    ).then(
      httpResponse => {
        this.sessionStorageService.setItem(
          SessionStorageKeys.authorizationToken,
          httpResponse.token
        );
        isSuccessfull = true;
      },
      httpError => {
        if (
          httpError.status === 422 &&
          httpError?.error['message'] === 'EnrollmentCode'
        ) {
          this.form
            .get('code')
            .setErrors(
              new ValidationMessagesError(
                'enrollmentCode',
                null,
                this.cmsPageContent.validationMessages.enrollmentCode.otherErrorMessage
              )
            );
          this.loadingService.close();
        } else if (httpError.error.message.includes('is not Serviced')) {
          this.loadingService.close();
          this.isStateEligible = false;
          this.isStateNotEligible();
        }
      }
    );

    if (!isSuccessfull) {
      return;
    }

    this.updategoogleAnalyticsVariables();
    this.applicationDataService.replaceApplicationForm({
      form: formData
    });

    if (this.resumeCookie) {
      this.cookieService.remove(CookieEnums.ResumeByAppId);
    }

    this.userAppStarted.setUserApplicationStatus(true);
    this.neuroIdService.setVariable('funnel', ApplicationFlow.OrganicPrequal);
    this.router.navigate([this.nextPage]);
  }

  private createApplicationRequest(
    formData: GettingStarted,
    existingDataDiff?
  ): ApplicationForm {
    const dob = moment(formData.dob, 'MMDDYYYY');

    const application: ApplicationForm = {
      continuePath: this.nextPage,
      applicant: {
        identity: {
          socialSecurityNumber: formData.ssn,
          firstName: formData.firstname,
          lastName: formData.lastname,
          dateOfBirth: dob.format('YYYY-MM-DD')
        },
        emails: [
          {
            key: '1',
            address: formData.email,
            type: 'Personal'
          }
        ],
        phones: [
          {
            key: '1',
            number: formData.mobile,
            type: 'Mobile'
          }
        ],
        residences: [
          {
            key: '1',
            type: 'Current',
            address: {
              zipCode: formData.postalCode,
              stateCode: formData.state,
              line1: formData.addressLine1,
              city: formData.city
            }
          }
        ]
      }
    };

    if (this.shortAppOffer) {
      // cleaning empty shortAppOffer properties
      Object.entries(this.shortAppOffer).map((value: any) => {
        if (!value[1]) {
          delete this.shortAppOffer[value[0]];
        }
      });

      application.shortAppOffer = {
        ...this.shortAppOffer
      };
    }

    if (
      formData.addressLine1 ||
      existingDataDiff?.some(item => ['suite'].includes(item.path[0]))
    ) {
      application.applicant.residences[0].address.line2 =
        formData.addressLine2 || ' ';
    }
    if (formData.suffix) {
      application.applicant.identity.suffix = formData.suffix;
    }
    if (
      formData.code ||
      existingDataDiff?.some(item => ['code'].includes(item.path[0]))
    ) {
      application.enrollmentCode = formData.code || ' ';
    }
    if (this.isMailingSectionEnabled) {
      if (formData?.alternatePhoneNumber) {
        application.applicant.phones.push({
          key: '2',
          number: formData.alternatePhoneNumber,
          type: formData.alternatePhoneType
        });
      }

      application.applicant.residences.push({
        key: '2',
        type: 'Mailing',
        address: {
          zipCode: formData?.mailingAddress_postalCode || formData.postalCode,
          stateCode: formData?.mailingAddress_state || formData.state,
          line1: formData?.mailingAddress_addressLine1 || formData.addressLine1,
          line2:
            formData?.mailingAddress_addressLine2 ||
            formData.addressLine2 ||
            ' ',
          city: formData?.mailingAddress_city || formData.city
        }
      });
    }

    return application;
  }

  private appendApplicationData(): void {
    const formData = this.createApplicationRequest(this.form.value);

    this.applicationDataService.replaceApplicationForm({ form: formData });
    this.applicationApi.append(formData).subscribe(() => {
      this.router.navigate(['verify-info']);
      this.loadingService.close();
    }, (error: any)=> {
      if (error.error.message.includes('is not Serviced')) {
        this.loadingService.close();
          this.isStateEligible = false;
          this.isStateNotEligible();
      } 
    });
  }

  private createGettingStartedData(form: ApplicationForm): GettingStarted {
    if (!form) {
      return null;
    }

    const dateOfBirth = moment(
      form?.applicant?.identity?.dateOfBirth,
      'YYYY-MM-DD'
    ).format('MM/DD/YYYY');
    const address = form?.applicant?.residences?.find(
      residence => residence.type === 'Current'
    ).address;

    const email = form?.applicant?.emails?.find(
      emailInfo => emailInfo.type === 'Personal'
    );
    const phone = form?.applicant?.phones?.find(
      phoneInfo => phoneInfo.key === '1'
    );

    let addressMailing;
    let alternatePhone;

    if (this.isMailingSectionEnabled) {
      addressMailing = form?.applicant?.residences?.find(
        residence => residence.type === 'Mailing'
      )?.address;

      alternatePhone = form?.applicant?.phones?.find(
        phoneInfo => phoneInfo.key === '2'
      );

      if (addressMailing) {
        Object.keys(address).forEach(k => {
          if (address[k] !== addressMailing[k]) {
            this.isMailingAddressSameChecked = false;
          }
        });
      }
    }
    const ssn = form?.applicant?.identity?.socialSecurityNumber;

    const gettingStarted: GettingStarted = {
      firstname: form?.applicant?.identity?.firstName,
      lastname: form?.applicant?.identity?.lastName,
      suffix: form?.applicant?.identity?.suffix,
      dob: dateOfBirth,
      addressLine1: address?.line1,
      addressLine2: address?.line2?.trim(),
      city: address?.city,
      state: address?.stateCode,
      postalCode: address?.zipCode,
      email: email?.address,
      mobile: phone?.number,
      alternatePhoneNumber: alternatePhone?.number,
      alternatePhoneType: alternatePhone?.type,
      code: form.enrollmentCode?.trim()
    };

    if (!ssn.includes('X')) {
      gettingStarted.ssn = ssn;
    }

    return gettingStarted;
  }

  private populateData(): void {
    const applicationForm = this.applicationDataService.getApplication()?.form;
    if (applicationForm) {
      setTimeout(() => {
        this.form.patchValue(this.createGettingStartedData(applicationForm));
      });
    } else {
      this.userAppStarted.setUserApplicationStatus(false);
      if (this.route.snapshot.queryParams['sf']) {
        const sfDataObject: any = JSON.parse(
          atob(decodeURIComponent(this.route.snapshot.queryParams['sf']))
        );
        this.form.patchValue(sfDataObject);
      } else if (this.route.snapshot.queryParams['id']) {
        this.callStartOptionById(this.route.snapshot.queryParams['id']);
      } else if (this.route?.snapshot?.queryParams['redirect']) {
        const redirectDataObject: any = JSON.parse(
          atob(decodeURIComponent(this.route.snapshot.queryParams['redirect']))
        ) as GettingStarted;
        if (redirectDataObject?.abTesting) {
          this.abTestingFromRedirect = redirectDataObject?.abTesting;
        }
        this.form.patchValue(redirectDataObject);
      }
    }
    if (this.form.value.state) {
      this.onStateUpdate(this.form.value.state);
    }
  }

  public checkForStateMessage(stateAbbreviation: string): void {
    const message = this.stateMessageService.getStateMessage(
      stateAbbreviation,
      this.route.snapshot.data.cmsContent.stateMessages.stateMessage
    );
    const stateHasChanged = this.lastSelectedState !== stateAbbreviation;

    if (message && stateHasChanged) {
      const stateModalRef = this.modalService.open(StateMessageModalComponent, {
        windowClass: 'state-message-modal',
        centered: true
      });
      this.cmsPageContent.modals.stateMessageModal.content = message.message;
      stateModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.stateMessageModal;
    }

    this.lastSelectedState = stateAbbreviation;
  }

  private async callStartOptionById(cookieAppID): Promise<void> {
    this.loadingService.open();

    try {
      const startOptionByIdResponse = await lastValueFrom(
        this.applicationApi.startOptionsByID({
          applicationId: cookieAppID,
          brand: this.environment.brand
        })
      );
      if (
        startOptionByIdResponse.startOptions === StartOption.ResumeOnly ||
        startOptionByIdResponse.startOptions === StartOption.ResumeOrStartNew
      ) {
        this.loadingService.close();

        const resumeModalRef = this.modalService.open(ResumeModalComponent, {
          backdrop: 'static',
          windowClass: 'resume-modal'
        });
        console.log()
        resumeModalRef.componentInstance.data = {
          ...startOptionByIdResponse,
          applicationId: cookieAppID,
          firstName: this.form.value.firstname
        };
        resumeModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.resumeModal;
        resumeModalRef.result.then(
          data => {
            if (data && !data.resumeApplication) {
              this.cookieService.remove(CookieEnums.ResumeByAppId);
            }
          },
          reason => {
            this.resumeCanceled.emit(true);
          }
        );
        return;
      } else {
        this.cookieService.remove(CookieEnums.ResumeByAppId);
        this.loadingService.close();
        this.resumeCanceled.emit(true);
      }
    } catch {
      this.cookieService.remove(CookieEnums.ResumeByAppId);
      this.modalService.dismissAll();
    }
  }

  public get recaptchaIsHidden(): boolean {
    return this.debugHud.disableReCaptcha;
  }

  private updategoogleAnalyticsVariables(): void {
    const variables: GoogleTagManagerVariables = {
      state: this.form.value.state,
      application_start_date: moment.utc().format(),
      userType: ApplicationFlow.OrganicPrequal
    };

    if (this.form.value.code) {
      variables.enrollment_code = this.form.value.code;
    }
    this.googleAnalytics.setGoogleTagManagerVariables(variables);
  }
  public debugHudSubmit(): void {
    this.debugHud.simulateData();
    setTimeout(() => {
      this.consentsComponent?.debugHudSubmitAllConsents();
      this.consentToCommunicationComponent?.debugHudSubmitAllConsents();
    });
  }

  public onMailingAddressSameChange(event: any): void {
    this.isMailingAddressSameChecked = event.target.checked;
    var data: GoogleTagData = {
      step_name: event.target.id
    };
    this.googleAnalytics.formInteractionEvent(data);
  }

  private populateShortAppData(): void {
    const applicationForm = this.applicationDataService.getApplication()?.form;
    if (applicationForm?.shortAppOffer) {
      this.shortAppOffer = applicationForm.shortAppOffer;
    } else if (this.route.snapshot.queryParams['offer']) {
      this.shortAppOffer = JSON.parse(
        atob(decodeURIComponent(this.route.snapshot.queryParams['offer']))
      ) as ShortAppOffer;
      if (this.shortAppOffer.code?.trim()) {
        this.formConfig.codeConfig.disabled = 'true';
      }
    }

    if (
      !this.shortAppOffer ||
      this.shortAppOfferRequiredFields.some(
        field => this.shortAppOffer[field] == null
      )
    ) {
      this.redirectToStartPage();
    }
    this.form.patchValue(this.shortAppOffer);
  }

  private redirectToStartPage(): void {
    this.window.location.href = this.environment.sessionExpirationRedirectUrl;
  }

  private fillVariablesInCmsOfferDetails(): void {
    if (this.cmsOfferDetails?.section != null) {
      this.cmsOfferDetails.section = this.convertToArray(
        this.cmsOfferDetails.section
      );
      this.cmsOfferDetails.section.forEach(section => {
        if (section.sectionText != null) {
          section.sectionText = this.convertToArray(section.sectionText);
          section.sectionText.forEach(sectionLine => {
            const formattedAnnualFeePerMonth = this.currencyPipe.transform(
              this.shortAppOffer?.annualFee / 12,
              'USD',
              'symbol',
              '1.0-2'
            );
            sectionLine.line = sectionLine.line.replace(
              '{annualFeePerMonth}',
              formattedAnnualFeePerMonth
            );
          });
        }
      });
    }
  }

  private convertToArray(entity): any {
    if (entity == null) {
      return [];
    }

    if (Array.isArray(entity)) {
      return entity;
    }

    return [entity];
  }
  public async ngOnDestroy(): Promise<void> {
    if (this.formChangesSubscription != null) {
      this.formChangesSubscription.unsubscribe();
    }
  }
}
