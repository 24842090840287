import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom, of, Subject } from 'rxjs';
import { GettingStartedFormGroup } from './getting-started.form';
import * as GettingStartedFormConfig from './getting-started.form.config';
import { StateMessageModalComponent } from './state-message-modal/state-message-modal.component';
import { StateMessageService } from '@core/state-message/state-message-service';
import {
  AddressConfiguration,
  EditAddressControls,
  EditAddressLabels,
  getDefaultAddressControls
} from '@elevate/address';
import { GettingStartedValidator } from './getting-started.validator';
import { StateEligibilityService } from './state-eligibility/state-eligibility.service';
import { AppInsightsService } from '@core/app-insights/app-insights.service';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import { StateNotServicedModalComponent } from './state-not-serviced-modal/state-not-serviced-modal.component';
import { Environment } from '@environment/environment';
import {
  CmsGettingStarted,
  CmsMailingAddress,
  CmsOfferDetails
} from './getting-started.content';
import { ConsentSectionItem } from '@application/consents/consents.content';
import { AffectCreditScoreModalComponent } from './affect-credit-score-modal/affect-credit-score-modal.component';
import { RadioButton } from '@elevate/ui-components';
import {
  FormControl,
} from '@angular/forms';
import { ShortAppOffer } from '@application/application';
import { TodayCardHandleService } from '@core/brand-handle-services/today-card-handle.service';
import {
  ConfigurationService,
  EnrollmentCodeStatesResponse,
  PlatformConfigFeatures
} from '@core/configuration/configuration.service';
import { ConsentHelper } from '@application/consents/consent.helper';
import { CurrencyPipe } from '@angular/common';


export declare interface GettingStartedPageBase {
  recaptchaIsHidden: boolean;
  showDebugHud: string;
  submit(): void;
}

@Component({ template: '' })
export class GettingStartedBaseComponent implements OnDestroy, OnInit {
  protected onDestroyed = new Subject<boolean>();
  public formConfig = GettingStartedFormConfig;
  public creditScoreImpactDisclosure: any;

  public lastSelectedState: string;

  public isDisabled: any;
  public model: NgbDateStruct;
  public date: { year: number; month: number };
  public isStateEligible = true;

  public addressConfig: AddressConfiguration;
  public addressLabels: EditAddressLabels;

  public consentToCommunicationContainerName = 'consentToCommunicationConsents';
  public addressControls: EditAddressControls;
  public mailingAddressControls: EditAddressControls;
  public cmsPageContent: CmsGettingStarted;
  public consentsSection: ConsentSectionItem[];
  public consentToCommunication: ConsentSectionItem[] = [];
  public cmsMailingAddressContent: CmsMailingAddress;
  public cmsOfferDetails: CmsOfferDetails;
  public isMailingSectionEnabled: boolean;
  public isMailingAddressSameChecked: boolean;
  public alternatePhoneEnabled: boolean;
  public radioButtonItem: RadioButton;
  public shortAppOffer: ShortAppOffer;
  public enrollmentCodeTooltip: string;
  public enrollmentCodeConfig: EnrollmentCodeStatesResponse;

  constructor(
    public form: GettingStartedFormGroup,
    public modalService: NgbModal,
    public stateMessageService: StateMessageService,
    public validators: GettingStartedValidator,
    public stateEligibilityService: StateEligibilityService,
    public googleAnalytics: GoogleAnalytics,
    public appInsightsService: AppInsightsService,
    public environment: Environment,
    public route: ActivatedRoute,
    public todayCardHandleService: TodayCardHandleService,
    public configurationService: ConfigurationService,
    public currencyPipe: CurrencyPipe
  ) {
    this.cmsPageContent = this.route.snapshot.data.cmsContent.prequalificationGettingStarted.gettingStartedCommon;

    this.addressLabels = {
      addressLine1: this.cmsPageContent.addressLabels.labels.addressLine1,
      addressLine2: this.cmsPageContent.addressLabels.labels.addressLine2,
      city: this.cmsPageContent.addressLabels.labels.city,
      state: this.cmsPageContent.addressLabels.labels.state,
      postalCode: this.cmsPageContent.addressLabels.labels.postalCode
    };
    this.addressConfig = {
      siteKey: this.environment.smartyStreets.siteKey,
      url: {
        lookup: this.environment.smartyStreets.url.suggest,
        lookupCheck: this.environment.smartyStreets.url.lookup
      },
      stateBlackListed: [],
      threeColumnDisplay: true
    };
  }

  public async ngOnInit(): Promise<void> {
    this.addressControls = getDefaultAddressControls(null, false, true);

    Object.keys(this.addressControls).forEach((key: string) => {
      this.form.addControl(key, this.addressControls[key]);
    });

    this.enrollmentCodeTooltip = this.cmsPageContent.messages.enrollmentCodeTooltip;
    this.consentsSection = ConsentHelper.converToArray(
      this.route.snapshot.data.cmsContent.prequalificationGettingStarted
        .consentsSection
    );
    if (this.cmsPageContent.disclosures.consentToCommunication != null) {
      this.consentToCommunication = [
        {
          consent: this.cmsPageContent.disclosures.consentToCommunication,
        }
      ];
    }
    this.cmsMailingAddressContent = this.route.snapshot.data.cmsContent.prequalificationMailingAddress;
    this.cmsOfferDetails = this.route.snapshot.data.cmsContent.offerDetails;
    if (this.cmsOfferDetails) {
      this.cmsOfferDetails.primeRateDisclosure = await this.todayCardHandleService.replaceMargin(
        this.cmsOfferDetails.primeRateDisclosure
      );
    }

    if (this.cmsMailingAddressContent) {
      this.form.addControl('mailingAddressCheckBox', new FormControl(true));
      this.isMailingSectionEnabled = true;
      this.isMailingAddressSameChecked = true;
      this.alternatePhoneEnabled = this.cmsMailingAddressContent.isenabled;
      if (this.alternatePhoneEnabled) {
        this.form.addAlternatePhoneControls();
        this.setAlternatePhoneRadioGroup();
      }
    }

    try {
      this.enrollmentCodeConfig = await lastValueFrom(
        this.configurationService.getConfigFeature<
          EnrollmentCodeStatesResponse
        >(PlatformConfigFeatures.DMCodeFieldRequired)
      );
    } catch (error) {
      console.error('ERROR: ENROLLMENT CODE CONFIGURATION NOT AVAILABLE');
    }
  }

  public ngOnDestroy(): void {
    this.onDestroyed.next(true);
  }

  public async onStateUpdate(stateCode: string): Promise<void> {
    lastValueFrom(
      this.stateEligibilityService.getStateResource(stateCode)
    ).then(res => {
      this.stateEligibilityService.setStateResourceAge(res?.ageRequirement);
      this.formConfig = {
        ...this.formConfig,
        dobConfig: {
          ...this.formConfig.dobConfig,
          maxDate: this.form.getCalendarMaxDate(res?.ageRequirement)
        }
      };
      this.isStateEligible = res && res.isLoanServiced;
      if (this.isStateEligible) {
        this.checkForStateMessage(stateCode);
      } else {
        this.isStateNotEligible();
      }
      this.changeEnrolmentCodeField(stateCode);
    });
  }

  public openAffectCreditScore(): void {
    const modalRef = this.modalService.open(AffectCreditScoreModalComponent);
    modalRef.componentInstance.htmlContent = this.cmsPageContent.modals.affectCreditScoreModal;
  }

  public checkForStateMessage(stateAbbreviation: string): void {
    const message = this.stateMessageService.getStateMessage(
      stateAbbreviation,
      this.route.snapshot.data.cmsContent.stateMessages
    );
    const stateHasChanged = this.lastSelectedState !== stateAbbreviation;

    if (message && stateHasChanged) {
      const stateModalRef = this.modalService.open(StateMessageModalComponent, {
        windowClass: 'state-message-modal',
        centered: true
      });
      this.cmsPageContent.modals.stateMessageModal.content = message.message;
      stateModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.stateMessageModal;
    }

    this.lastSelectedState = stateAbbreviation;
  }

  public isStateNotEligible(): boolean {
    if (!this.isStateEligible) {
      const stateNotModalRef = this.modalService.open(
        StateNotServicedModalComponent,
        {
          windowClass: 'state-not-serviced-modal'
        }
      );
      stateNotModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.stateNotMessageModal;
    }
    return !this.isStateEligible;
  }

  private setAlternatePhoneRadioGroup(): void {
    this.formConfig.alternatePhoneRadioGroupConfig.buttons = [];
    this.cmsMailingAddressContent.altPhoneRadioGroup.forEach(element => {
      this.radioButtonItem = {
        id: 'alternatePhone' + element.displayLabel,
        label: element.displayLabel,
        name: 'alternatePhoneButtonGroup',
        value: element.value,
        attributes: {
          'data-nid-target':
            'alternatePhone' + element.displayLabel + 'RadioButton'
        },
        disabled: 'false'
      };
      this.formConfig.alternatePhoneRadioGroupConfig.buttons.push(
        this.radioButtonItem
      );
    });
  }

  private async changeEnrolmentCodeField(stateCode: string): Promise<void> {
    if (this.enrollmentCodeConfig) {
      const enrollmentCodeIsRequired = this.enrollmentCodeConfig?.states.find(
        state => state.name === stateCode
      );

      if (!this.enrollmentCodeConfig.enabled) {
        return;
      }

      if (enrollmentCodeIsRequired) {
        this.form.setEnrollmentCodeField(
          this.cmsPageContent.validationMessages,
          true
        );

        this.enrollmentCodeTooltip = this.cmsPageContent.messages.requiredEnrollmentCodeTooltip;
      }

      if (!enrollmentCodeIsRequired) {
        this.form.setEnrollmentCodeField(
          this.cmsPageContent.validationMessages,
          false
        );

        this.enrollmentCodeTooltip = this.cmsPageContent.messages.enrollmentCodeTooltip;
      }
    }
  }
}
