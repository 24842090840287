import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment';
import { Observable, lastValueFrom } from 'rxjs';
import {
  StateLinesApiResponse,
  StateResourceModel
} from './stateResourceModel';

@Injectable({ providedIn: 'root' })
export class StateEligibilityService {
  public ageMin: number;
  public constructor(
    private httpClient: HttpClient,
    private environment: Environment,
  ) {}

  public getStateResource(
    stateCode: string,
    brandCode?: string
  ): Observable<StateResourceModel> {
    return this.httpClient.get<StateResourceModel>(
      `${
        this.environment.brandApi.url
      }/api/product-configuration?stateCode=${stateCode}&brandId=${
        this.environment.brandId
      }${brandCode == null ? '' : `&brandCode=${brandCode}`}`
    );
  }

  public getStateOptions(
    stateCode: string,
    brandCode?: string
  ): Promise<StateResourceModel> {
    return lastValueFrom(this.httpClient.get<StateResourceModel>(
      `${
        this.environment.brandApi.url
      }/api/product-configuration?stateCode=${stateCode}&brandId=${
        this.environment.brandId
      }${brandCode == null ? '' : `&brandCode=${brandCode}`}`
    ));
  }

  public getStateLines(
    stateCode: string,
    brandCode: string
  ): Observable<StateLinesApiResponse> {
    return this.httpClient.get<StateLinesApiResponse>(
      `${this.environment.brandApi.url}/api/product-statelines?stateCode=${stateCode}&brandCode=${brandCode}`
    );
  }

  public setStateResourceAge(age?: number): void {
    this.ageMin = age;
  }

  public getStateResourceAge(): number {
    return this.ageMin ?? 18;
  }
}
