/* istanbul ignore file */
import { Injectable } from '@angular/core';
import {
  SessionStorageService,
  SessionStorageObjects
} from '@core/session-storage/session-storage.service';

import deepMerge from 'deepmerge';
import { Environment } from '@environment/environment';
import { AppInsightsService } from '@core/app-insights/app-insights.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApplicationFlow } from '@application/application';
import { ApplicationDataService } from '@application/application.service';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export enum GoogleTagEvent {
  Click = 'click',
  View = 'view',
  Checkbox = 'check-box',
  Popup = 'pop-up',
  FormField = 'form-field',
  Notification = 'notification',
  API = 'api',
  PageSubmission = 'page-submission',
  Initialization = 'initialization',
  Rendering = 'rendering',
  TrackedData = 'tracked-data',
  SequenceIdCreation = 'sequence-id-creation',
  PlaidEvent = 'plaid-event',
  PageLoad = 'page-load',
  FinicityEvent = 'finicty-event'
}

export enum FormType {
  Application = 'application',
  Login = 'login',
  Finicity = 'finicity'
}

export enum ModalClickAction {
  Check = 'Check',
  UnCheck = 'Uncheck',
  Click = 'Click',
  Cta_Click = 'Cta_Click',
  Close = 'Close'
}

export interface GoogleTagManagerVariables {
  full_referral_url?: string;
  visitorid?: string;
  gcid?: string;
  deviceType?: string;
  browser?: string;
  logrocket_id?: string;
  enrollment_code?: string;
  product_code?: string;
  pre_qual_amount?: number;
  state?: string;
  dm_ec_type?: string;
  sequence_id?: number;
  resumed_app?: boolean;
  application_start_date?: string;
  application_complete_date?: string;
  application_id?: string;
  approved_amount?: number;
  neuroId?: string;
  environment?: string;
  lead_id?: string;
  offer_id?: string;
  aud_id?: string;
  userType?: string;
  lenderCode?: string;
  loan_id?: string;
  loan_date?: string;
  loan_first_payment_date?: string;
  loan_requested_amount?: number;
  loan_amount?: number;
  loan_apr?: number;
  appInsightsSession_Id?: string;
  sub_id?: string;
  abTesting?: AbTestingData[];
}

export interface AbTestingData {
  testName?: string;
  segmentName?: string;
}

export interface GoogleTagData {
  [property: string]: number | string | string[];
  form_id?: string;
  form_name?: string;
  form_destination?: string;
  form_submit_text?: string;
  form_type?: string;
  finicity_value?: string;
  step_name?: string;
  field_id?: string;
  bankDataProvider_event?: string;
  bankDataProvider?: string;
}

export interface GtagModalData {
  [property: string]: number | string | string[];
  event?: string;
  modal_name?: string;
  link_text?: string;
  action?: string;
  modal_url?: string;
}

export interface GtagLinkData {
  [property: string]: number | string | string[];
  link_id?: string;
  link_text?: string;
  link_classes?: string;
  link_type?: string;
  link_domain?: string;
  link_url?: string;
  action?: string;
}

@Injectable({ providedIn: 'root' })
export class GoogleAnalytics {
  constructor(
    private sessionStorageService: SessionStorageService,
    private environment: Environment,
    private appInsightsService: AppInsightsService,
    private deviceService: DeviceDetectorService,
    private applicationDataService: ApplicationDataService
  ) {}

  public setOneTimeGlobalData() {
    var asignedProduct = this.applicationDataService.getApplication()?.product?.id;
    var productId = asignedProduct? asignedProduct.toString(): this.environment.brandId.toString(); //if no productId is asigned we use brandId

    var appInsightsDetails = this.appInsightsService.getGoogleTagAppInsightsTelemetry();
    var globalData: GoogleTagManagerVariables = {
      browser: this.deviceService.browser,
      appInsightsSession_Id: appInsightsDetails.appInsightsSesssionId,
      deviceType: this.deviceService.deviceType,
      product_code: productId,
      environment: this.environment.googleAnalytics.environment
    };

    this.setGoogleTagManagerVariables(globalData, false);
  }

  public pushPageLeveldata() {
    let storageGtagVariables = this.sessionStorageService.getObject(
      SessionStorageObjects.googleTagManagerVariables
    );
    window.dataLayer.push(storageGtagVariables);
  }
  public ctaClickEvent(data: GtagLinkData): void {
    data.event = 'cta_click';

    window.dataLayer.push(data);
  }

  public formViewEvent(): void {
    var formName = this.getGoogleVariableValue('userType');
    var data: GoogleTagData = {
      event: 'form_view',
      form_type: FormType.Application,
      form_name: formName,
      form_id: this.getFormId(formName)
    };
    window.dataLayer.push(data);
  }

  public formSubmitEvent(data: GoogleTagData): void {
    data.event = 'form_submit';
    data.form_type = FormType.Application;
    data.form_name = this.getGoogleVariableValue('userType');
    data.form_id = this.getFormId(data.form_name);

    data.form_submit_text =
      data?.form_submit_text != null ? data.form_submit_text : null;

    window.dataLayer.push(data);
  }

  private getFormId(formName: string) {
    return formName == ApplicationFlow.OrganicPrequal
      ? 'NewAppFlow'
      : formName == ApplicationFlow.Reapply
      ? 'ReApplyFlow'
      : formName == ApplicationFlow.PartnerPrepop
      ? 'PartnerFlow'
      : null;
  }
  public formInteractionEvent(data: GoogleTagData): void {
    data.event = 'form_interaction';
    (data.form_name = this.getGoogleVariableValue('userType')),
      (data.form_id = this.getFormId(data.form_name));
    data.form_type =
      data.form_type == null ? FormType.Application : data.form_type;
    data.form_submit_text =
      data?.form_submit_text != null ? data.form_submit_text : null;

    window.dataLayer.push(data);
  }

  public formStartEvent(data: GoogleTagData): void {
    data.event = 'form_start';
    data.form_name = this.getGoogleVariableValue('userType');
    data.form_id = this.getFormId(data.form_name);
    data.form_type =
      data.form_type == null ? FormType.Application : data.form_type;
    data.form_submit_text =
      data?.form_submit_text != null ? data.form_submit_text : null;

    window.dataLayer.push(data);
  }
  public getGoogleVariableValue(variableName: string) {
    let storageGtagVariables = this.sessionStorageService.getObject(
      SessionStorageObjects.googleTagManagerVariables
    );
    if (storageGtagVariables != null) {
      const gtagVariables = storageGtagVariables as GoogleTagManagerVariables;
      return gtagVariables[variableName];
    } else return null;
  }

  public resetGoogleVariableValue(variableName: string) {
    let storageGtagVariables = this.sessionStorageService.getObject(
      SessionStorageObjects.googleTagManagerVariables
    );
    if (storageGtagVariables != null) {
      const gtagVariables = storageGtagVariables as GoogleTagManagerVariables;
      gtagVariables[variableName] = null;
      this.sessionStorageService.setObject(
        SessionStorageObjects.googleTagManagerVariables,
        gtagVariables
      );
    }
  }
  public formStepEvent(data: GoogleTagData): void {
    data.event = 'form_step';
    data.form_type = FormType.Application;
    data.form_name = this.getGoogleVariableValue('userType');
    data.form_id = this.getFormId(data.form_name);
    if (data.step_name != undefined) {
      window.dataLayer.push(data);
    }
  }

  public viewModalEvent(data: GtagModalData): void {
    data.event = 'view_modal';
    data.modal_url = window.location.href;
    window.dataLayer.push(data);
  }

  public clickModalEvent(data: GtagModalData): void {
    data.event = 'modal_click';
    data.link_text = data.link_text?.replace('<p>', '').replace('</p>', '');
    data.modal_url = window.location.href;
    window.dataLayer.push(data);
  }

  public navClickEvent(data: GoogleTagData): void {
    data.event = 'nav_click';
    data.step_name = window.location.href;
    window.dataLayer.push(data);
  }

  public expandAccordionEvent(data: GtagLinkData): void {
    data.event = 'expand_click';
    window.dataLayer.push(data);
  }

  public clickAccordionEvent(data: GtagLinkData): void {
    data.event = 'accordion_click';
    data.action = 'cta_click';

    window.dataLayer.push(data);
  }

  public collapseAccordionEvent(data: GtagLinkData): void {
    data.event = 'collapse_click';
    window.dataLayer.push(data);
  }

  //This method tracks all the variable values that are being set accross multiple pages
  //Tracking required  pages call this method to set some variable data among the set of variables being tracked
  //We save the latest variable data and store it back to the session variable
  //When we push the gtm events , we use this latest variable data by retriving from session variables and log it to google

  public setGoogleTagManagerVariables(
    gtagVariables: GoogleTagManagerVariables,
    pushData: boolean = false
  ): void {
    let storageGtagVariables = this.sessionStorageService.getObject(
      SessionStorageObjects.googleTagManagerVariables
    );

    let gtagVariablesMerge = deepMerge(storageGtagVariables, gtagVariables);

    this.sessionStorageService.setObject(
      SessionStorageObjects.googleTagManagerVariables,
      gtagVariablesMerge
    );

    if (pushData) {
      window.dataLayer.push(gtagVariablesMerge);
    }
  }

  //This method logs form interaction events when there is a form value change

  public logFormInteractionEventsForChangedValues(
    formOld: any,
    formNew: any,
    isformInteraction: boolean = true,
  ) {
    var arrayObject = Object.entries(formOld);
    var arrayObjectNew = Object.entries(formNew);
    for (var i = 0; i < arrayObject.length; i++) {
      if (arrayObject[i][1] != arrayObjectNew[i][1]) {
        var submitdata: GoogleTagData = {
          step_name: window.location.pathname,
          field_id: arrayObject[i][0]
        };
        if (isformInteraction) {
          this.formInteractionEvent(submitdata);
        } else {
          this.formStartEvent(submitdata);
        }
      }
    }
  }
}
