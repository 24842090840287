import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageFormGroup } from '@core/page-form-group/page-form-group';

export interface FundingPaymentFormType {
  fundingPaymentMethod: string;
}

@Injectable()
export class FundingPaymentSelectionForm extends PageFormGroup {

  constructor() {
    super({
      fundingPaymentMethod: new FormControl('Electronic')
    });
  }
}
