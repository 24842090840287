<ecl-base-modal
  [modalConfig]="modalData"
  class="resume-body"
  (closeModal)="
    googleAnalytics.clickModalEvent({
      action: 'Close',
      modal_name: this.htmlContent.title
    })
  "
  (openModal)="
    googleAnalytics.viewModalEvent({
      modal_name: this.htmlContent.title
    })
  "
>
  <div *ngIf="page === 'WelcomeWithOptions'">
    <h1 id="welcomeWithOptionsHeader">{{ content.welcomeHeader }}</h1>
    <div id="welcomeWithOptionsContent" class="welcome-content">
      <p [innerHTML]="content.welcomeContent | safe: 'html'"></p>
    </div>
    <div class="ecl-button-hgroup">
      <button
        id="resumeBtn"
        class="ecl-button-primary"
        (click)="page = 'SendCode'"
      >
        {{ content.buttons.yes }}
      </button>
      <button
        id="startNewBtn"
        class="ecl-button-secondary"
        (click)="startNew()"
      >
        {{ content.buttons.no }}
      </button>
    </div>
  </div>
  <div *ngIf="page === 'WelcomeWithoutOptions'">
    <h1 id="welcomeWithoutOptionsHeader">{{ content.welcomeHeader }}</h1>
    <div id="welcomeWithoutOptionsContent" class="welcome-content">
      <p [innerHTML]="content.welcomeWithoutOptionsContent | safe: 'html'"></p>
    </div>
    <button
      id="resumeOnlyContinueBtn"
      class="ecl-button-primary"
      (click)="page = 'SendCode'"
    >
      {{ content.buttons.continue }}
    </button>
  </div>
  <div *ngIf="page === 'SendCode'">
    <h1 id="sendCodeHeader">
      {{ content.verifyHeader }}
    </h1>
    <div id="sendCodeContent" class="sendcode-text">
      <p [innerHTML]="content.verifyContent | safe: 'html'"></p>
    </div>
    <form [formGroup]="contactTypeForm">
      <ecl-radio
        [formControlConfig]="formConfig.contactTypeConfig"
        formControlName="sendCodeMethod"
      >
      </ecl-radio>
      <button
        class="ecl-button-primary"
        id="sendCodeBtn"
        (click)="sendVerificationCode()"
      >
        {{ content.buttons.sendCode }}
      </button>
    </form>
  </div>

  <div *ngIf="page === 'VerifyCode'">
    <h1 id="verifyCodeHeader">
      {{ content.enterHeader }}
    </h1>
    <form class="col-sm-8" [formGroup]="verificationForm">
      <ecl-number
        id="verificationCodeInput"
        autocomplete="off"
        [formControlConfig]="formConfig.verificationCodeConfig"
        formControlName="verificationCode"
        withStringOutput="true"
      >
        {{ content.verificationCode }}
      </ecl-number>
    </form>
    <div class="ecl-button-hgroup">
      <button
        id="verifyCodeButton"
        class="ecl-button-primary"
        (click)="verificationSubmit()"
      >
        {{ content.buttons.submit }}
      </button>
      <button id="goBackButton" class="ecl-button-secondary" (click)="goBack()">
        {{ content.buttons.back }}
      </button>
    </div>
  </div>
  <div *ngIf="page === 'VerificationSucess'">
    <h1 id="verificationSucessHeader">
      {{ thanksHeader }}
    </h1>
    <div id="verificationSucessContent">
      <p [innerHTML]="content.thanksContent | safe: 'html'"></p>
    </div>
    <button
      id="resumeAppContinueBtn"
      class="ecl-button-primary"
      (click)="resumeApplication()"
    >
      {{ content.buttons.continue }}
    </button>
  </div>
</ecl-base-modal>
