import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

export interface CmsContentAttribute {
  id?: string;
  href?: string;
  target?: string;
}

export interface CmsContentListener {
  click?: (event: any) => boolean | void;
}

export interface CmsContentReplaceOptions {
  text?: string;
  attribute?: CmsContentAttribute;
  listener?: CmsContentListener;
}

@Injectable({ providedIn: 'root' })
export class LegacyCmsService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public replaceTag(selector: string, options: CmsContentReplaceOptions): void {
    const element: HTMLElement = document.querySelector(selector);

    if (!element) {
      throw new Error(`element ${selector} was not found in cms string`);
    }

    const text = this.renderer.createText(options.text ?? element.textContent);

    const newElement: HTMLElement = this.renderer.createElement(
      element.tagName
    );
    this.renderer.appendChild(newElement, text);

    if (options.attribute) {
      this.setProperties(options.attribute, newElement);
    }

    if (options.listener) {
      this.setListener(options.listener, newElement);
    }

    element.classList.forEach((className: string) => {
      this.renderer.addClass(newElement, className);
    });

    this.renderer.insertBefore(element.parentElement, newElement, element);

    if (element.parentNode) {
      element.parentNode.removeChild(element);
    }
  }

  private setProperties(
    attribute: CmsContentAttribute,
    newElement: HTMLElement
  ): void {
    const attributeNames = Object.keys(attribute);

    attributeNames.forEach((attributeName: string) =>
      this.renderer.setProperty(
        newElement,
        attributeName,
        attribute[attributeName]
      )
    );
  }

  private setListener(
    listener: CmsContentListener,
    newElement: HTMLElement
  ): void {
    const attributeNames = Object.keys(listener);

    attributeNames.forEach((listenerName: string) =>
      this.renderer.listen(newElement, listenerName, listener[listenerName])
    );
  }
}
