<div class="page-container">
  <div class="page-subtitle subtitle-text">
    {{ content.subHeader }}
  </div>

  <form [formGroup]="form" (submit)="submit()" class="account-form">
    <div class="line-text">
      <ecl-text-input
        class="col-md-8"
        formControlName="email"
        [formControlConfig]="emailConfig"
        googleAnalyticsFocusOut="email"
      >
        {{ content.labels.email }}
      </ecl-text-input>
    </div>
    <div *ngIf="userNameEnabled" class="line-text-middle">
      <ecl-username
        [usernameControls]="usernameControls"
        [formContent]="userFormContent"
        googleAnalyticsFocusOut="username"
      >
      </ecl-username>
    </div>
    <div class="line-text">
      <ecl-password
        [passwordControls]="passwordControls"
        [formContent]="passFormContent"
        googleAnalyticsFocusOut="password"
      >
      </ecl-password>
    </div>

    <div class="continue-button-container">
      <button
        id="accountSetupContinueButton"
        type="submit"
        class="ecl-button-primary"
        googleAnalyticsClick="AccountSetUp-Continue"
      >
        {{ content.buttons.continue | uppercase }}
      </button>
    </div>
  </form>
</div>

<app-debug-hud>
  <button (click)="debugHudService.generateData()">
    generate data
  </button>
</app-debug-hud>
