import { Injectable } from '@angular/core';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import { ConsentSectionItem } from '@application/consents/consents.content';

export interface VerifyInfo {
  creditReportDisclosure?: boolean;
  consentToCommunication?: boolean;
  consentToElectronics?: boolean;
  correctInformationConfirmation?: boolean;
  requestedAmount?:number;
}

@Injectable()
export class VerifyInfoFormGroup extends PageFormGroup {
  public value: VerifyInfo = {
    creditReportDisclosure: false,
    consentToCommunication: false,
    consentToElectronics: false,
    correctInformationConfirmation: false
  };

  constructor() {
    super({});
  }

  public formOnChange(consentsSection: ConsentSectionItem[]) {
    this.valueChanges.subscribe((form: any) => {
      consentsSection.forEach((consent: ConsentSectionItem, index: number) => {
        if (consent.consent.defaultConsent.key === "ConsentToCommunication") {
          this.value.consentToCommunication = form[`consentControl_${index}`];
        }
      });
    });
  }
}
