<ng-container *ngIf="cmsPageContent">
  <div class="five-day-container">
    <div class="rise-bar-container">
      <div tabindex="0" class="five-day-wrapper">
        <img
          tabindex="0"
          id="fiveDayImage"
          class="calender-logo"
          src="{{ cmsPageContent?.imageDisplay?.desktopUrl?.url }}"
          alt="{{ cmsPageContent?.imageDisplay?.altText }}"
        />
        <div>
          <div tabindex="0" id="fiveDayTitleText" class="title-text">
            {{ cmsPageContent.header }}
          </div>
          <small tabindex="0" id="fiveDayBodyText" class="small">
            {{ cmsPageContent.subtitle }}
          </small>
        </div>
      </div>
    </div>
  </div>
</ng-container>
