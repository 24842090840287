export interface Banner {
  bannerName: string;
  bannerContent: string;
  bannerType: BannerType;
}

export enum BannerType {
  Informative = 'Informative',
  Alert = 'Alert',
  Success = 'Success',
  Error = 'Error'
}

export interface BannersContainer {
  banners: Banner[];
}

export interface BannersResponse {
  banners: DisplayBanner[];
}

export interface DisplayBanner {
  bannerCmsName: string;
}