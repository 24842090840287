import { Injectable } from '@angular/core';
import { mergeObjectInArrayByKey } from '@core/deep-merge/merge-object-in-array-by-key';
import deepmerge from 'deepmerge';

import { ApplicationData, Disclosure } from './application';
import { ConsentHelper } from './consents/consent.helper';
import { Consent } from './consents/consents.content';
import { ConsentModalAgreement } from './consents/e-signature/signature.content';
import { TrackedRequests, UnderwritingModel } from './underwriting/underwriting.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationDataService {
  private application: ApplicationData = {};

  public merge(
    application: ApplicationData,
    preserveSSN: boolean = true
  ): void {
    let originalSSN: string;
    if (Object.keys(this.application).length) {
      originalSSN = this.application.form.applicant.identity
        .socialSecurityNumber;
    }
    this.application = deepmerge(this.application, application, {
      customMerge: mergeObjectInArrayByKey
    });
    if (originalSSN && preserveSSN) {
      this.application.form.applicant.identity.socialSecurityNumber = originalSSN;
    }
  }

  public mergeArray(application: ApplicationData): void {
    this.application = deepmerge(this.application, application);
  }

  public replaceApplicationForm(applicationData: ApplicationData): void {
    this.application.form = { ...applicationData.form };
  }

  public addTrackedRequests(trackedRequests: Array<TrackedRequests>){
    this.application.trackedRequests = [... trackedRequests]
  }

  public updateUnderwriting(underwriting: UnderwritingModel){
    this.application.underwriting = underwriting;
  }

  public getApplication(): ApplicationData {
    return this.application;
  }

  public resetApplication(): void {
    this.application = {};
  }

  public getConsentDisclosures(consent: Consent): Disclosure[] {
    const agreements = ConsentHelper.getConsentAgreements(consent);
    const disclosureKeys = agreements.map(agreement =>
      ConsentHelper.generateDisclosureKeyByAgreement(consent, agreement)
    );
    disclosureKeys.push(ConsentHelper.generateDisclosureKeyByConsent(consent));
    return (
      this.application?.form?.disclosures?.filter(
        x => disclosureKeys.indexOf(x.key) >= 0
      ) ?? []
    );
  }

  public getAgreementDisclosure(
    consent: Consent,
    agreement: ConsentModalAgreement
  ): Disclosure {
    const result = this.application?.form?.disclosures?.filter(
      x =>
        x.key ===
        ConsentHelper.generateDisclosureKeyByAgreement(consent, agreement)
    );
    return result?.length > 0 ? result[0] : null;
  }
}
