import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationApi } from '@application/application.api';
import { ApplicationDataService } from '@application/application.service';
import {
  AccountTypeModalCmsContents,
  AccountTypeModalComponent,
  AccountTypeModalResult
} from '@application/connect-bank/account-type-modal/account-type-modal.component';
import {
  CheckingAccountModalCmsContents,
  CheckingAccountModalComponent,
  CheckingAccountModalResult
} from '@application/connect-bank/checking-account-modal/checking-account-modal.component';
import {
  BankConnectionApi,
  BankConnectionApiRequest,
  BankConnectionApiResponse
} from '@application/connect-bank/connect-bank-api.service';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BankConnectSuccessObject } from './bank-connect.types';
import { ApplicationData, BankDataProviders } from '@application/application';
import { lastValueFrom } from 'rxjs';
import { TrackedRequests } from '@application/underwriting/underwriting.model';
import {
  ConfigurationService,
  Decision,
  DecisionFlowResponse,
  PlatformConfigFeatures
} from '@core/configuration/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class HandleBankConnectionResponseService {
  public bankConnectionError = false;
  public achRoutingError = false;
  public accountNotPermittedError = false;
  public failedServerCallError = false;
  public gTagEvent: string;
  public bankConnectionApiRequest: BankConnectionApiRequest;
  public isProgressError: boolean = false;
  public noExistingAccounts: boolean = false;
  public bankRequiredCodes: string[];

  constructor(
    private applicationDataService: ApplicationDataService,
    private bankConnectionApi: BankConnectionApi,
    private applicationApiService: ApplicationApi,
    private loadingService: LoadingModalService,
    private modalService: NgbModal,
    private router: Router,
    private configurationService: ConfigurationService
  ) {}

  public async callBankConnection(
    successObject: BankConnectSuccessObject,
    routePath: string,
    googleTagPath?: string,
    accountTypeCmsData?: AccountTypeModalCmsContents,
    checkingAccountCmsData?: CheckingAccountModalCmsContents
  ): Promise<void> {
    this.resetFlags();

    const getConfigFeatureDecisionFlow: DecisionFlowResponse = await lastValueFrom(
      this.configurationService.getConfigFeature<DecisionFlowResponse>(
        PlatformConfigFeatures.DecisionFlow
      )
    );

    const filterBankRequiredDecisions: Decision[] = getConfigFeatureDecisionFlow.decision.filter(
      (decision: Decision) =>
        decision.bankRequired === true
    );

    this.bankRequiredCodes = filterBankRequiredDecisions.map((decision: Decision) => decision.code);

    this.gTagEvent = googleTagPath;

    const application = this.applicationDataService.getApplication();
    setTimeout(() => this.loadingService.open());
    if (
      application.product.bankDataProvider.toUpperCase() ===
      BankDataProviders.Finicity
    ) {
      this.bankConnectionApiRequest = {
        applicationId: application.id,
        provider: application.product.bankDataProvider.toUpperCase(),
        clientName: application.brand,
        reportId: successObject.metaData.reportId
      };
    } else {
      this.bankConnectionApiRequest = {
        publicToken: successObject.publicToken,
        selectedAccountId: successObject.metaData.account_id,
        applicationId: this.applicationDataService.getApplication().id,
        provider: application.product.bankDataProvider.toUpperCase()
      };
    }

    try {
      const bankConnectionResponse: HttpResponse<BankConnectionApiResponse> = await lastValueFrom(
        this.bankConnectionApi.postBankConnectResults(
          this.bankConnectionApiRequest
        )
      );
      this.achRoutingError = bankConnectionResponse.body.isErrored;

      if (!bankConnectionResponse.body.checkingAccountSelected) {
        this.loadingService.close();

        if (
          bankConnectionResponse.body.accounts.length > 0 &&
          application.product.bankDataProvider.toUpperCase() ===
          BankDataProviders.Finicity
        ) {
          const checkingAcctModalRef: NgbModalRef = this.modalService.open(
            CheckingAccountModalComponent,
            {
              windowClass: 'checking-acct-modal',
              backdrop: 'static',
              keyboard: false
            }
          );

          checkingAcctModalRef.componentInstance.checkingAcctModalData = {
            bankConnectGuid: bankConnectionResponse.body.bankConnectionId,
            bankconnectionReq: this.bankConnectionApiRequest,
            availableAccounts: [...bankConnectionResponse.body.accounts],
            cmsData: checkingAccountCmsData
          };

          checkingAcctModalRef.result.then(async modalResult => {
            if (modalResult.result === CheckingAccountModalResult.Success) {
              this.loadingService.open();

              await lastValueFrom(
                this.applicationApiService.append(
                  modalResult.selectedAccountPayload
                )
              );

              this.applicationDataService.merge({
                form: { applicant: { bank: bankConnectionResponse.body } }
              } as ApplicationData);

              this.applicationDataService.merge({
                form: modalResult.selectedAccountPayload
              });

              this.appendAndNavigatetoNextPage(routePath);
            } else if (
              modalResult.result === CheckingAccountModalResult.Error
            ) {
              this.showFailureMessage();
            }
          });
        } else {
          const accountTypeModalRef: NgbModalRef = this.modalService.open(
            AccountTypeModalComponent,
            {
              windowClass: 'account-type-modal',
              backdrop: 'static',
              keyboard: false
            }
          );

          accountTypeModalRef.componentInstance.accountTypeModalData = {
            availableAccounts: [...bankConnectionResponse.body.accounts],
            cmsData: accountTypeCmsData
          };

          accountTypeModalRef.result.then(async modalResult => {
            if (modalResult.result === AccountTypeModalResult.Success) {
              this.loadingService.open();

              await lastValueFrom(
                this.applicationApiService.append(
                  modalResult.selectedAccountPayload
                )
              );

              this.applicationDataService.merge({
                form: { applicant: { bank: bankConnectionResponse.body } }
              } as ApplicationData);

              this.applicationDataService.merge({
                form: modalResult.selectedAccountPayload
              });

              this.appendAndNavigatetoNextPage(routePath);
            }
          });
        }
      }

      if (bankConnectionResponse.body.checkingAccountSelected) {
        this.appendAndNavigatetoNextPage(routePath);

        this.applicationDataService.merge({
          form: {
            applicant: {
              bank: { draftFromKey: '1', ...bankConnectionResponse.body }
            }
          }
        } as ApplicationData);
      }
    } catch (error) {
      if (
        error.error?.message === 'Invalid bank information.' &&
        error.status === 400
      ) {
        this.accountNotPermittedError = true;
        this.loadingService.close();
        setTimeout(() => window.scrollTo(0, 0));
      } else {
        this.achRoutingError = true;
        this.appendAndNavigatetoNextPage(routePath);
      }
    }
  }

  public async appendAndNavigatetoNextPage(routePath): Promise<void> {
    const applicantRfaiStatus = this.applicationDataService
      .getApplication()
      .trackedRequests?.find(
        (request: TrackedRequests) => (request.type = 'Rfai')
      );

    const isRfaiApplicant = this.bankRequiredCodes?.includes(
      applicantRfaiStatus?.name
    );

    //only for connect-bank page, applicant needs to be forced to manual bank-information page
    if (this.achRoutingError && !isRfaiApplicant) {
      this.loadingService.close();
      return;
    }

    await lastValueFrom(
      this.applicationApiService.append({ continuePath: routePath })
    );

    this.loadingService.close();
    this.router.navigate([routePath]);
  }

  public showFailureMessage(): void {
    this.failedServerCallError = true;
    this.modalService.dismissAll();
    setTimeout(() => window.scrollTo(0, 0));
  }

  public resetFlags(): void {
    this.bankConnectionError = false;
    this.accountNotPermittedError = false;
    this.failedServerCallError = false;
    this.achRoutingError = false;
  }
}
