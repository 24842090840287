import { Component, Inject, OnInit } from '@angular/core';
import { ApplicationDataService } from '@application/application.service';
import { UnderwritingApiService } from '@application/underwriting/underwriting-api.service';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { SessionStorageService } from '@core/session-storage/session-storage.service';
import { Environment } from '@environment/environment';
import { ActivatedRoute } from '@angular/router';
import { FinalPageContent } from '@application/final-page/final-page-content';
import { AppInsightsService } from '@core/app-insights/app-insights.service';
import { AcceptedTerms, ApplicationData } from '@application/application';

@Component({
  selector: 'app-final-page',
  templateUrl: './final-page.component.html',
  styleUrls: ['./final-page.component.scss']
})
export class FinalPageComponent implements OnInit {
  public content: FinalPageContent;
  public application: ApplicationData;
  public acceptedTerms: AcceptedTerms;

  constructor(
    private cmsPageContentService: CmsPageContentService,
    private environment: Environment,
    private sessionStorageService: SessionStorageService,
    private appDataService: ApplicationDataService,
    private underwritingService: UnderwritingApiService,
    @Inject('window') private window: Window,
    private route: ActivatedRoute,
    private appInsightsService: AppInsightsService
  ) {}

  public ngOnInit(): void {
    this.content = this.route.snapshot.data.cmsContent.successfulSetup;
    this.application = this.appDataService.getApplication();
    this.acceptedTerms = this.application?.acceptedTerms;
    if(this.content?.postBookingLoanDetails) {
      this.content.postBookingLoanDetails = this.updateContent();
      const firstName = this.application.form.applicant.identity.firstName;
      const lastName = this.application.form.applicant.identity.lastName;
      this.cmsPageContentService.updatePageTitle(this.getPageTitle(firstName, lastName));
    } else {
      this.cmsPageContentService.updatePageTitle(this.content.header);
    }
  }

  public goToLoginPage(): void {
    const bauLoginUrl = `${this.environment.bau.secureUrl}/${this.environment.navigation.login}`;
    this.appInsightsService.trackEvent("Navigate-To-BAU-Login", { bauLoginUrl: bauLoginUrl });
    this.sessionStorageService.clearAll();
    this.underwritingService.resetDecision();
    this.appDataService.resetApplication();
    this.window.location.href = bauLoginUrl;
  }

  private updateContent() {
    const date = new Date(this.acceptedTerms?.terms?.firstPaymentAt);
    const firstPaymentAt = date?.toLocaleString('en-US', {day:'2-digit',month:'2-digit',year:'numeric'}).split(',')[0];
    this.content.postBookingLoanDetails.paymentInformation = this.content?.postBookingLoanDetails?.paymentInformation?.replace('{paymentAmount}',
    this.acceptedTerms.terms.paymentAmount?.toString())?.replace('{firstPaymentDate}',firstPaymentAt)?.replace('{numberOfPayments}', 
    this.acceptedTerms.terms.numberOfPayments?.toString())?.replace('{paymentFrequency}', this.paymentFrequencyDisplayText);
    this.content.postBookingLoanDetails.loanAmountAdditionalInformation = this.content?.postBookingLoanDetails?.loanAmountAdditionalInformation?.replace('{aprPrecentage}',(this.acceptedTerms?.terms?.tilaApr)?.toString())
    return this.content.postBookingLoanDetails;
  }

  private getPageTitle(firstName: string, lastName: string): string {
    return this.content.header?.replace('{fullName}', firstName+ ' '+ lastName);
  }
  public get paymentFrequencyDisplayText(): string {
    return this.content?.postBookingLoanDetails?.paymentFrequency[(this.application.form?.applicant?.income?.sources[0]?.frequency)?.toLowerCase()];
  }
}
