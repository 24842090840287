<div class="bank-information-page-container" role="form">
  <p
    class="page-section-title h3-override"
    id="bankInfoSubTitleText"
    role="heading"
    aria-label="description"
    aria-level="2"
  >
    {{ pageContent.subHeader }}
  </p>
  <div class="ecl-notifications ecl-bottom-m">
    <ng-container #giactErrorNotificationsBox> </ng-container>
  </div>
  <form [formGroup]="form" (submit)="submit()" class="bank-info-form">
    <ecl-number
      role="textbox"
      aria-label="routing number"
      class="col"
      [formControlConfig]="routeNoConfig"
      formControlName="bankRoutingNumber"
      (focus)="showBankRoutingNumberImage = true"
      withStringOutput="true"
      (blur)="showBankRoutingNumberImage = false"
      id="bankRoutingNumberInputField"
      googleAnalyticsFocusOut="bankRoutingNumberInputField"
    >
      {{ pageContent.labels.bankRoutingNumber }}
    </ecl-number>
    <img
      id="checkRoutingNumberImage"
      src="{{ checkRoutingImgURL }}"
      alt="location of routing number in a check"
      class="check-image"
      *ngIf="showBankRoutingNumberImage"
    />

    <ecl-number
      role="textbox"
      aria-label="bank account number"
      class="col"
      [formControlConfig]="bankAcctConfig"
      formControlName="bankAccountNumber"
      withStringOutput="true"
      (blur)="handleBankAccountInput($event)"
      (focus)="handleBankAccountInput($event)"
      id="bankAccountNumberInputField"
      googleAnalyticsFocusOut="bankAccountNumberInputField"
    >
      {{ pageContent.labels.checkingAccountNumber }}
    </ecl-number>

    <img
      id="checkAccountNumberImage"
      src="{{ checkAccountImgURL }}"
      alt="location of account number in a check"
      class="check-image"
      *ngIf="showBankAccountNumberImage"
    />
    <ecl-number
      role="textbox"
      aria-label="confirm bank account number"
      class="col"
      withStringOutput="true"
      [formControlConfig]="confirmBankAcctConfig"
      formControlName="confirmBankAccountNumber"
      id="confirmAccountNumberInputField"
      googleAnalyticsFocusOut="confirmAccountNumberInputField"
    >
      {{ pageContent.labels.confirmCheckingAccountNumber }}
    </ecl-number>

    <app-consents
      #consentsComponent
      [formGroup]="form"
      [consentSectionItems]="consentsSection"
    ></app-consents>

    <div id="bankInfoContinueButtonDiv">
      <button
        id="bankInfoContinueButton"
        type="submit"
        class="ecl-button-primary"
        googleAnalyticsClick="ManualBank-Continue"
      >
        {{ pageContent.buttons.continue }}
      </button>
    </div>
  </form>
</div>

<app-debug-hud>
  <button (click)="debugHudFillSubmit()">fill and submit</button>
</app-debug-hud>
