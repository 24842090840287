<div class="sign-submit-container" [formGroup]="form">
  <div class="sign-submit-subheader subtitle-text">
    {{ content.subHeader }}
  </div>

  <div class="sign-submit-checkboxes-container">
    <app-consents
      #consentsComponent
      [formGroup]="form"
      [consentSectionItems]="content.consentsSection"
      [hideDividingLine]="true"
    ></app-consents>
  </div>
  <div
    *ngIf="content.labels.signatureInputEnabled"
    class="sign-submit-input-container"
  >
    <app-typed-name
      [agreement]="agreementConfig"
      [formGroup]="form"
    ></app-typed-name>
  </div>
  <div *ngIf="content.buttons.buttonPreText" class="sign-submit-button-subtext">
    <span> <i class="ecl-info-icon"></i> </span>

    <span [innerHTML]="content.buttons.buttonPreText | safe: 'html'"></span>
  </div>

  <div class="sign-submit-button-container">
    <button
      focusOnError
      id="signSubmitContinueButton"
      type="submit"
      class="ecl-button-primary"
      (click)="onSubmit()"
      googleAnalyticsClick="SignSubmitContinue"
    >
      {{ content.buttons.submit | uppercase }}
    </button>
  </div>
</div>

<app-debug-hud>
  <button (click)="debugHudSign()">
    sign disclosures
  </button>
</app-debug-hud>
