import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import { DefaultModalComponent } from '@core/default-modal/default-modal.component';
import { Environment } from '@environment/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, delay, retryWhen, takeWhile, tap } from 'rxjs';

export enum DisclosureTypes {
  WhatYouNeedToKnow = 'WhatYouNeedToKnow',
  ElasticTermsAndConditions = 'ElasticTermsAndConditions'
}

export interface CmsDisclosureElement {
  name: string;
  html: string;
}

export interface DisclosureApiResponse {
  disclosures: CmsDisclosureElement[];
}

export interface DocumentTypeGenerated {
  documentType: string;
  htmlContent: string;
  documentCollectionId: string;
  documentId: string;
}

/* istanbul ignore next */
@Injectable({ providedIn: 'root' })
export class DocumentApi {
  constructor(
    private environment: Environment,
    private http: HttpClient,
    private modalService: NgbModal,
    private loadingModalService: LoadingModalService
  ) {}

  public openErrorModal(): void {
    const modalRef = this.modalService.open(DefaultModalComponent);
    modalRef.componentInstance.htmlContent = {
      header: "Documents",
      content: "Document processing, please try again.",
      button: "OK"
    };
  }

  public get(type: string): Observable<any> {
    const options: any = {
      headers: { 'Content-Type': 'application/pdf' },
      responseType: 'blob'
    };
    return this.http.get<any>(
      `${this.environment.brandApi.url}/api/v1/document?type=${type}`,
      options
    ).pipe(
      retryWhen((error: any) => {
        let retryCount = 1;
        return error.pipe(
          delay(this.environment.httpRetry.retryTimeout),
          takeWhile(() => retryCount <= this.environment.httpRetry.retryCount),
          tap(
            () => retryCount++,
            () => {},
            () => {
              this.loadingModalService.close();
              this.openErrorModal();
              throw new Error();
            }
          )
        );
      })
    );
  }

  public getDisclosures(
    disclosures: DisclosureTypes[]
  ): Observable<DisclosureApiResponse> {
    const queryParams = [];

    disclosures.forEach((disclosure: DisclosureTypes) => {
      queryParams.push(`name=${disclosure}`);
    });

    return this.http.get(
      `${this.environment.brandApi.url}/api/v1/disclosures?${queryParams.join(
        '&'
      )}`
    ) as Observable<DisclosureApiResponse>;
  }

  public generateDocument(documentType: string): Observable<DocumentTypeGenerated>{
    return this.http.get(
      `${this.environment.brandApi.url}/api/v1/get-document-html?documentType=${documentType}`
    ) as Observable<DocumentTypeGenerated>;
  }
}
