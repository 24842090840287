import {
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
  AfterViewInit
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  Data,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { UserInactivityStatusService } from '@core/inactivity-module/user-inactivity.service';
import { LocationProxy } from '@core/location-proxy/location-proxy.service';
import {
  GoogleAnalytics,
  GoogleTagEvent,
  GtagLinkData,
  GtagModalData,
  ModalClickAction
} from '@core/google-analytics/googleanalytics.service';
import { map, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, Observable, forkJoin, lastValueFrom, of } from 'rxjs';
import { CmsService } from '@core/cms';
import { Environment } from '@environment/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import { TodayCardHandleService } from '@core/brand-handle-services/today-card-handle.service';
import { BrandName } from '@core/brand-handle-services/brand-name';
import { ConsentHelper } from '@application/consents/consent.helper';
import { Cobrowse } from '@elevate/layout';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import { ApplicationDataService } from '@application/application.service';
import { UnderwritingApiService } from '@application/underwriting/underwriting-api.service';
import {
  SessionStorageService,
  SessionStorageObjects
} from '@core/session-storage/session-storage.service';
import { RestrictNavigationModalComponent } from '@application/restrict-navigation/restrict-navigation-modal/restrict-navigation-modal.component';
import { CobrowseConfig, CobrowseContent } from '@elevate/cobrowse';
import { ConfigurationService } from '@core/configuration/configuration.service';
import { BannerService } from './banner/banners.service';
import { Banner } from './models/banner.model';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit, AfterViewInit {
  private cmsContent$: Observable<any>;
  private cmsContentSignSubmit$: Observable<any>;

  public cookieNotificationContent$: Observable<any>;

  public footerContent$: Observable<any>;
  public rawHeaderCms$: Observable<any>;
  public fiveDayRiskFreeGuarantee$: Observable<any>;
  public showCookieNotification: boolean;
  public banners$: BehaviorSubject<Array<Banner>>;
  public cobrowseHtmlContent: CobrowseContent;
  public cobrowseConfig: CobrowseConfig;
  public isCobrowseEnabled: boolean = false;
  public previousPageDetails: string;
  public onCytPage = false;
  public cobrowse: Cobrowse;
  constructor(
    private router: Router,
    private environment: Environment,
    private modalService: NgbModal,
    private titleService: Title,
    private route: ActivatedRoute,
    private googleanalytics: GoogleAnalytics,
    private locationProxy: LocationProxy,
    private userInactivityService: UserInactivityStatusService,
    private cmsService: CmsService,
    private applicationFlowService: ApplicationFlowService,
    private todayCardHandleService: TodayCardHandleService,
    private loadingService: LoadingModalService,
    private sessionStorageService: SessionStorageService,
    private appDataService: ApplicationDataService,
    private underwritingService: UnderwritingApiService,
    private configurationService: ConfigurationService,
    private bannerService: BannerService,
    @Inject('window') private window: Window
  ) {
    this.cmsContent$ = this.cmsService
      .getEntries('layout')
      .pipe(shareReplay(1));
    this.cmsContentSignSubmit$ = this.cmsService
      .getByReferences('bank_income', [
        'sign_and_submit',
        'sign_and_submit.consents_section.consent.default_consent',
        'sign_and_submit.consents_section.consent.states_consents.consent'
      ])
      .pipe(shareReplay(1));
      this.cookieNotificationContent$ = this.cmsContent$.pipe(map(res =>  {
        res.cookieNotification.content = (res.cookieNotification.content)?.replaceAll('{environment}',this.environment.bau.url);
         return res.cookieNotification;
      }))

    this.footerContent$ = forkJoin([this.cmsContent$, of(false)]).pipe(
      map(x => [x[0], false])
    );
    this.rawHeaderCms$ = this.cmsContent$.pipe(map(x => x.header));
    this.fiveDayRiskFreeGuarantee$ = this.cmsContent$.pipe(map(x => x.headerDisclosure));
    this.showCookieNotification = this.environment.showCookieNotification;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const pageUrl = event.url.split('/');
        const pageName = pageUrl[pageUrl.length - 1].split('?')[0];

        // fix footer menu item navigation for pages with query params.
        // Remove after update @elevate/layout to 14+
        const currentUrl = this.router.url.split('/');
        const currentPage = currentUrl[currentUrl.length - 1].split('?');
        if (
          currentPage.length > 1 &&
          currentPage[0] === pageName &&
          event.url.split('?').length === 1
        ) {
          this.router.navigate([pageName], {
            queryParams: this.route.snapshot.queryParams
          });
        }
      }
      if (event instanceof NavigationEnd) {
        let pageUrl = event.url.split('/');
        let pageName = pageUrl[pageUrl.length - 1].split('?')[0];
        if (!pageName) {
          pageUrl = event.urlAfterRedirects.split('/');
          pageName = pageUrl[pageUrl.length - 1].split('?')[0];
        }
        if (this.loadingService.isOpen) {
          this.loadingService.close();
        }
        this.applicationFlowService.saveContinuePath(pageName);
        this.userInactivityService.pingInterruptionToIdle();

        this.previousPageDetails = this.router
          .getCurrentNavigation()
          ?.previousNavigation?.finalUrl?.toString();
      }
      const pageComponentRoute = this.getPageComponentRoute(this.route);
      const routeData = pageComponentRoute.snapshot.data;
      this.setTitle(routeData);
      this.cobrowseConfig = this.environment
        ?.coBrowseConfiguration as CobrowseConfig;
    });
    const coBrowseEnabledFlag = this.sessionStorageService.getObject(
      SessionStorageObjects.coBrowseEnabledFlag
    );
    if (coBrowseEnabledFlag == null) {
      this.configurationService.IsCoBrowseEnabled().subscribe(data => {
        this.isCobrowseEnabled = data;
        this.sessionStorageService.setObject(
          SessionStorageObjects.coBrowseEnabledFlag,
          data
        );
      });
    } else {
      this.isCobrowseEnabled = coBrowseEnabledFlag;
    }
  }

  public async ngOnInit(): Promise<void> {
    await lastValueFrom(this.footerContent$).then(() => {
      this.brandConfiguration();
      this.preventRightClickOnPricing();
    });
    await lastValueFrom(this.cmsService.getEntries('layout')).then(data => {
      this.cobrowseConfig.linkText = 'Co-Browse';
      if (this.isCobrowseEnabled) {
        this.cobrowse = { content: data.coBrowse, config: this.cobrowseConfig };
      }
    });
    this.googleanalytics.formStepEvent({
      form_destination: this.locationProxy.pathname,
      step_name: this.previousPageDetails
    });
    this.banners$ = this.bannerService.activeBanners$;
  }

  public async ngAfterViewInit(): Promise<void> {
    this.googleanalytics.pushPageLeveldata();

    setTimeout(() => {
      const modalClickAnchor = document.getElementsByTagName('a');

      const allanchorElements: Element[] = Array.from(modalClickAnchor);

      for (let item of allanchorElements) {
        if (item.getAttribute('cookie-banner')?.includes('Consent')) {
          const consentPromptBannerEventData:GtagModalData = {
            action: ModalClickAction.Cta_Click,
            modal_name: item.attributes['cookie-banner']?.value,
          }
          item.addEventListener('click', (event: any) => {
            consentPromptBannerEventData.link_text = event.target.innerText;
            this.googleanalytics.clickModalEvent(consentPromptBannerEventData)
          });
        } else if (item.getAttribute('data-ga4') === null) {
          const clickData: GtagLinkData = {
            link_id: item.id,
            link_text: item.textContent,
            link_classes: item.className,
  
            link_domain: item.baseURI,
            link_url:
              item.attributes['href']?.value == null
                ? this.locationProxy.href
                : item.attributes['href']?.value
          };
          clickData.link_type = 'page_cta_link';

          item.addEventListener('click', () => {
            this.googleanalytics.ctaClickEvent(clickData);
          });
        }
      }
      document.getElementById("closeNotificationButton")?.addEventListener('click',(event:any) => {
        const consentPromptBannerEventData:GtagModalData = {
          action: ModalClickAction.Close,
          modal_name: 'Consent CCPA Banner',
          link_text: ModalClickAction.Close
        }
        this.googleanalytics.clickModalEvent(consentPromptBannerEventData);
      });
    }, 3000);
  }

  public onNavClick($event: any) {
    const clickData: GtagLinkData = {
      link_id: $event.target.id,
      link_text: $event.target.textContent,
      link_classes: $event.target.className,
      link_domain: $event.target.baseURI,
      link_url: $event.target.attributes['href']?.value,
      link_type: 'footer_link'
    };
    this.googleanalytics.navClickEvent(clickData);
  }
  public get accountBaseUrl(): string {
    return this.environment?.navigation?.accountBaseUrl;
  }
  public get publicBaseUrl(): string {
    return this.environment?.navigation?.publicBaseUrl;
  }

  public logoClicked(event: string): void {
    // if event === 'home' it means that user clicked on main logo.
    // else event may contain the menu item id that was clicked (e.g. 'signOut')
    if (event !== 'home') {
      return;
    }

    const pageComponentRoute = this.getPageComponentRoute(this.route);
    const routeData = pageComponentRoute.snapshot.data;
    const flow = routeData.flow ?? this.route.snapshot.data.flow;
    if (flow === 'application') {
      const confirmationModal = this.modalService.open(
        RestrictNavigationModalComponent,
        {
          backdrop: 'static',
          centered: true
        }
      );

      confirmationModal.result.then((modalResult: boolean) => {
        if (modalResult) {
          this.sessionStorageService.clearAll();
          this.appDataService.resetApplication();
          this.underwritingService.resetDecision();
          this.redirectToBau();
        }
      });
    } else {
      this.redirectToBau();
    }
  }

  public closeBanner(bannerName: string): void {
    this.bannerService.closeBanner(bannerName);
  }

  getNotificationStatus(isCookieBannerEnabled: boolean) {
    if(isCookieBannerEnabled) {
      const data: GtagModalData = {
        modal_name: 'Consent CCPA Banner',
        link_text: null
      }
      this.googleanalytics.viewModalEvent(data);
    }
  }
  private setTitle(routeData: Data): void {
    const title = routeData.title;
    this.titleService.setTitle(title);
  }

  private getPageComponentRoute(route: ActivatedRoute): ActivatedRoute {
    if (route.firstChild) {
      return this.getPageComponentRoute(route.firstChild);
    }
    return route;
  }

  private redirectToBau(): void {
    this.window.location.href = this.environment.bau.url;
  }

  private redirectToSignIn(): void {
    this.window.location.href = `${this.environment.bau.secureUrl}/${this.environment.navigation.login}`;
  }

  public signIn(event: string): void {
    if (event !== 'signIn') {
      return;
    }

    const pageComponentRoute = this.getPageComponentRoute(this.route);
    const routeData = pageComponentRoute.snapshot.data;
    const flow = routeData.flow ?? this.route.snapshot.data.flow;
    if (flow === 'application') {
      const confirmationModal = this.modalService.open(
        RestrictNavigationModalComponent,
        {
          backdrop: 'static',
          centered: true
        }
      );

      confirmationModal.result.then((modalResult: boolean) => {
        if (modalResult) {
          this.sessionStorageService.clearAll();
          this.appDataService.resetApplication();
          this.underwritingService.resetDecision();
          this.redirectToSignIn();
        }
      });
    } else {
      this.redirectToSignIn();
    }
  }

  private async brandConfiguration(): Promise<void> {
    if (this.environment.brand === BrandName.TodayCard) {
      const cmsContent = await lastValueFrom(this.cmsContentSignSubmit$);
      let consentsSection = cmsContent[0].signAndSubmit.consentsSection;
      consentsSection = ConsentHelper.converToArray(consentsSection);
      const index = ConsentHelper.getStateConsentIndex(
        consentsSection,
        'ConsentToPricingAndTerms'
      );

      this.todayCardHandleService.configFooter(consentsSection[index].consent);
    }
  }

  private preventRightClickOnPricing(): void {
    document.addEventListener('contextmenu', event => {
      if ((event.target as any).id === 'pricing-&-terms') {
        event.preventDefault();
      }
    });
    document.addEventListener('auxclick', event => {
      if (
        (event as any).which === 2 &&
        (event.target as any).id === 'pricing-&-terms'
      ) {
        event.preventDefault();
      }
    });
  }
}
