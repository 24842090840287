import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ApplicationData } from '@application/application';
import { ApplicationDataService } from '@application/application.service';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import { DocumentTypeGenerated, DocumentApi } from '@core/document/document.api';
import { ConsentModalDocumentContent } from '../consents.content';

@Component({
  selector: 'app-document-html-content',
  templateUrl: './document-html-content.component.html',
  styleUrl: './document-html-content.component.scss'
})
export class DocumentHtmlContentComponent implements AfterViewInit, OnInit{

  public application: ApplicationData; 
  public documentsGenerated: DocumentTypeGenerated[] = new Array<DocumentTypeGenerated>();
  @Input() public documentContent: ConsentModalDocumentContent[];

  constructor(
    private appDataService: ApplicationDataService,
    private loadingService: LoadingModalService,
    private documentApi: DocumentApi
  ) {
    this.loadingService.open();
  }

  ngOnInit(): void {
    this.application = this.appDataService.getApplication();
    if(this.documentContent != null){
      this.getDocumentHtml(this.documentContent);
    }
  }

  ngAfterViewInit(): void {
    this.loadingService.close();
  }

  private getDocumentHtml(documentContent: ConsentModalDocumentContent[]) : void{
    documentContent?.forEach((document, index )=> {
      this.documentApi.generateDocument(document.documentType).subscribe(x => {
        x.documentType = document.documentType;
        this.documentsGenerated[index] = x;
      });
    });
  }

  public getDocuments(): DocumentTypeGenerated[]{
    return this.documentsGenerated;
  }
}
