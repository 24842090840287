import { Injectable, NgZone } from '@angular/core';
import { Router, ResolveEnd, ActivatedRouteSnapshot } from '@angular/router';
import { ScriptLoaderService } from '@core/script-loader/script-loader.service';
import { Environment } from '@environment/environment';
import { ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';

declare let nid: (a, b?, c?) => void;

/* istanbul ignore next */
@Injectable({ providedIn: 'root' })
export class NeuroIdService {
  private navigationEvents = new ReplaySubject<void | ActivatedRouteSnapshot>(1);
  private neuroIdEnabled: boolean;
  private neuroIdScriptUrl: string;
  public neuroIdSessionId: string = "Neuro Is Disabled";

  constructor(
    private scriptLoader: ScriptLoaderService,
    private environment: Environment,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.neuroIdEnabled = this.environment.neuroId.enabled;
    this.neuroIdScriptUrl = this.environment.neuroId.scriptUrl;
  }

  public init(): void {
    if (!this.neuroIdEnabled) {
      return;
    }

    this.router.events
      .pipe(filter((event: any) => event instanceof ResolveEnd))
      .subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.navigationEvents.next();
        }
      });

    // replay subject was implemented due to nid script loading delay was missing first navigation event
    this.scriptLoader.loadScript(this.neuroIdScriptUrl).then(() => {
      this.navigationEvents.subscribe((root: ActivatedRouteSnapshot) => {
        this.ngZone.runOutsideAngular(() => {
          nid('start');
          this.setNeuroSessionId();
        });
      });
    });
  }

  public sendUserId(userID: string): void {
    if (!this.neuroIdEnabled) {
      return;
    }

    nid('setUserId', userID);
  }

  public setCheckpoint(name: string): void {
    if (!this.neuroIdEnabled) {
      return;
    }

    nid('setCheckpoint', name);
  }

  public setVariable(type: string, name: string): void {
    if (!this.neuroIdEnabled) {
      return;
    }

    nid('setVariable', type, name);
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  public setNeuroSessionId(): void {
    const neuroIdservice = this;

    nid((api: any) => {
      const nidClientId = api.getIdentity();
      neuroIdservice.neuroIdSessionId = nidClientId;
    }, neuroIdservice);
  }
}
